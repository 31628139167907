import { aiBackgroundsApi } from '@/services/aiBackgrounds';
import { createSlice } from '@reduxjs/toolkit';

interface IAiBackgroundsState {
  aiBackgroundImage: string;
  isGeneratingPreviewImage: boolean;
  isImageGenerated: boolean;
}

const initialState: IAiBackgroundsState = {
  aiBackgroundImage: '',
  isGeneratingPreviewImage: false,
  isImageGenerated: false,
};

export const aiBackgroundsSlice = createSlice({
  initialState,
  name: 'aiBackgroundsSlice',
  reducers: {
    setIsGeneratingPreviewImage(state, { payload }) {
      state.isGeneratingPreviewImage = payload;
    },
    setIsImageGenerated(state, { payload }) {
      state.isImageGenerated = payload;
    },
    resetAiBackgroundsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      aiBackgroundsApi.endpoints.generateAiBackgroundImage.matchFulfilled,
      (state, response) => {
        state.aiBackgroundImage = `data:image/png;base64,${response.payload.data}`;
      }
    );
  },
});

export default aiBackgroundsSlice.reducer;

export const {
  resetAiBackgroundsState,
  setIsGeneratingPreviewImage,
  setIsImageGenerated,
} = aiBackgroundsSlice.actions;
