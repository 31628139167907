import { createSlice } from '@reduxjs/toolkit';
import { psdEngineApi } from '@/services/psdEngine';
import {
  Mockup,
  SmartObject,
  DesignAsset,
  ColorCombination,
  MockupVariationElement,
} from '@/services/types';

interface ICollectionState {
  mockup: Mockup;
  smartObjects: SmartObject[];
  activeSmartObject: SmartObject;
  designAssets: { [key: number]: DesignAsset[] };
  activeDesignAsset: DesignAsset;
  colorCombinations: { [key: number]: ColorCombination[] };
  activeColorCombination: ColorCombination;
  mockupVariations: MockupVariationElement[];
  originalMockupVariations: MockupVariationElement[];
  dummyActiveColor: string;
  colorPreviewVariation: string;
  colorPreviewVariationIsFetching: boolean;
  activeVariation: MockupVariationElement;
  isCreatingVariations: boolean;
  selectedHexColor: string;
  colorCombinationsContainerHeight: number;
  isMockupLoading: boolean;
}

const initialState: ICollectionState = {
  mockup: null!,
  smartObjects: null!,
  activeSmartObject: null!,
  designAssets: {},
  activeDesignAsset: null!,
  colorCombinations: {},
  activeColorCombination: null!,
  mockupVariations: [],
  originalMockupVariations: [],
  dummyActiveColor: '#FFFFFF',
  colorPreviewVariation: null!,
  colorPreviewVariationIsFetching: false,
  activeVariation: null!,
  isCreatingVariations: false,
  selectedHexColor: '#FFFFFF',
  colorCombinationsContainerHeight: 1,
  isMockupLoading: true,
};

export const collectionSlice = createSlice({
  initialState,
  name: 'collectionSlice',
  reducers: {
    resetCanvasState: () => initialState,
    setActiveSmartObject(state, { payload }) {
      state.activeSmartObject = payload;
    },
    setActiveColorCombination(state, { payload }) {
      state.activeColorCombination = payload;
    },
    setActiveDesignAsset(state, { payload }) {
      state.activeDesignAsset = payload;
    },
    setDummyActiveColor(state, { payload }) {
      state.dummyActiveColor = payload;
    },
    setColorPreviewVariation(state, { payload }) {
      state.colorPreviewVariation = payload;
    },
    setColorPreviewVariationState(state, { payload }) {
      state.colorPreviewVariationIsFetching = payload;
    },
    setActiveVariation(state, { payload }) {
      state.activeVariation = payload;
    },
    setIsCreatingVariations(state, { payload }) {
      state.isCreatingVariations = payload;
    },
    setDesignAssets(state, { payload }) {
      state.designAssets = payload;
    },
    setSelectedHexColor(state, { payload }) {
      state.selectedHexColor = payload;
    },
    setColorCombinationsContainerHeight(state, { payload }) {
      state.colorCombinationsContainerHeight = payload;
    },
    updateActiveSmartObject(state, { payload }) {
      state.activeSmartObject = {
        ...state.activeSmartObject,
        print_area: payload.print_area,
        fit: payload.fit,

        global_asset_top: payload.global_asset_top,
        global_asset_left: payload.global_asset_left,
        global_asset_width: payload.global_asset_width,
        global_asset_height: payload.global_asset_height,
      };
    },
    setIsMockupLoading(state, { payload }) {
      state.isMockupLoading = payload;
    },
    resetCollectionState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        psdEngineApi.endpoints.getMockupSmartObjects.matchFulfilled,
        (state, response) => {
          state.smartObjects = response.payload.data;

          const regex = /\/smart-object\/([^\/]+)/;
          const match = window.location.pathname.match(regex);

          if (match && match[1]) {
            const soUUID = match[1];
            if (
              response.payload.data.find(
                (so: SmartObject) => so.uuid === soUUID
              )
            ) {
              state.activeSmartObject = response.payload.data.find(
                (so) => so.uuid === soUUID
              ) as SmartObject;
            } else {
              state.activeSmartObject = response.payload.data[0];
            }
          } else {
            state.activeSmartObject = response.payload.data[0];
          }
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.getMockupSmartObjectAssets.matchFulfilled,
        (state, response) => {
          state.designAssets[state.activeSmartObject?.id] =
            response.payload.data;

          // state.activeDesignAsset = state.activeDesignAsset
          //   ? state.activeDesignAsset
          //   : response.payload.data[0];
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.getMockupSmartObjectColorCombinations
          .matchFulfilled,
        (state, response) => {
          state.colorCombinations[state.activeSmartObject?.id] =
            response.payload.data;

          // state.activeColorCombination = state.activeColorCombination
          //   ? state.activeColorCombination
          //   : response.payload.data[0];
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.loadPublicPsdMockup.matchFulfilled,
        (state, response) => {
          state.mockup = response.payload.data;
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.uploadPsdMockup.matchFulfilled,
        (state, response) => {
          state.mockup = response.payload.data;
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.getCurrentMockup.matchFulfilled,
        (state, response) => {
          state.mockup = response.payload.data;
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.deleteAssetVariation.matchFulfilled,
        (state, response) => {
          state.designAssets[state.activeSmartObject.id] = state.designAssets[
            state.activeSmartObject.id
          ].filter((asset) => asset.id !== response.payload.data.asset_id);

          if (response.payload.data.variation_delete) {
            state.mockupVariations = state.mockupVariations.filter(
              (variation) => {
                return !variation.variation_elements.some(
                  (element) =>
                    element.asset_id === response.payload.data.asset_id
                );
              }
            );
          } else {
            state.mockupVariations.forEach((variation) => {
              variation.variation_elements.forEach((element) => {
                if (element.asset_id === response.payload.data.asset_id) {
                  variation.isFetching = true;
                  if (variation.id === state.activeVariation?.id) {
                    state.activeVariation.isFetching = true;
                  }
                }
              });
            });
          }
          state.activeVariation = state.mockupVariations[0];
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.deleteColorVariation.matchFulfilled,
        (state, response) => {
          state.colorCombinations[state.activeSmartObject?.id] =
            state.colorCombinations[state.activeSmartObject?.id].filter(
              (color) => color.id !== response.payload.data.color_id
            );

          if (response.payload.data.variation_delete) {
            state.mockupVariations = state.mockupVariations.filter(
              (variation) => {
                return !variation.variation_elements.some(
                  (element) =>
                    element.color_id === response.payload.data.color_id
                );
              }
            );
          } else {
            state.mockupVariations.forEach((variation) => {
              variation.variation_elements.forEach((element) => {
                if (element.color_id === response.payload.data.color_id) {
                  variation.isFetching = true;
                  if (variation.id === state.activeVariation?.id) {
                    state.activeVariation.isFetching = true;
                  }
                }
              });
            });
          }
          state.activeVariation = state.mockupVariations[0];
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.addDesignAsset.matchFulfilled,
        (state, response) => {
          state.designAssets[state.activeSmartObject.id].push(
            ...response.payload.data
          );
          state.mockupVariations.forEach((variation) => {
            response.payload.data.forEach((addedAsset) => {
              variation.variation_elements.forEach((element) => {
                if (element.asset?.id === addedAsset.id) {
                  if (
                    JSON.stringify(element.asset) !== JSON.stringify(addedAsset)
                  ) {
                    variation.isFetching = true;
                    if (variation.id === state.activeVariation?.id) {
                      state.activeVariation.isFetching = true;
                    }
                  }
                }
              });
            });
          });
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.updateDesignAsset.matchFulfilled,
        (state, response) => {
          state.activeDesignAsset = response.payload.data;

          state.designAssets[state.activeSmartObject.id] = state.designAssets[
            state.activeSmartObject.id
          ].map((asset) => {
            if (asset.id === response.payload.data.id) {
              return response.payload.data;
            } else {
              return asset;
            }
          });
          // TODO: handle non existing global_replacement_enabled, not it is print_area from smart_object
          if (state.activeSmartObject.print_area === 1) {
            state.mockupVariations.forEach((variation) => {
              variation.variation_elements.forEach((element) => {
                if (element.asset?.id === response.payload.data.id) {
                  element.asset = JSON.parse(
                    JSON.stringify(response.payload.data)
                  );
                }
                // if (element.asset?.global_replacement_enabled === 1) {
                variation.isFetching = true;
                if (variation.id === state.activeVariation?.id) {
                  state.activeVariation.isFetching = true;
                }
                // }
              });
            });
          } else {
            state.mockupVariations.forEach((variation) => {
              variation.variation_elements.forEach((element) => {
                if (element.asset?.id === response.payload.data.id) {
                  if (
                    JSON.stringify(element.asset) !==
                    JSON.stringify(response.payload.data)
                  ) {
                    variation.isFetching = true;
                    if (variation.id === state.activeVariation?.id) {
                      state.activeVariation.isFetching = true;
                    }
                  }
                }
              });
            });
          }
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.addColorCombination.matchFulfilled,
        (state, response) => {
          state.colorCombinations[state.activeSmartObject.id].push(
            response.payload.data
          );

          state.mockupVariations.forEach((variation) => {
            variation.variation_elements.forEach((element) => {
              if (element.color?.id === response.payload.data.id) {
                if (
                  JSON.stringify(element.color) !==
                  JSON.stringify(response.payload.data)
                ) {
                  variation.isFetching = true;
                  if (variation.id === state.activeVariation?.id) {
                    state.activeVariation.isFetching = true;
                  }
                }
              }
            });
          });
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.updateColorCombination.matchFulfilled,
        (state, response) => {
          state.activeColorCombination = response.payload.data;

          state.colorCombinations[state.activeSmartObject?.id] =
            state.colorCombinations[state.activeSmartObject?.id].map(
              (color) => {
                if (color.id === response.payload.data.id) {
                  return response.payload.data;
                } else {
                  return color;
                }
              }
            );

          state.mockupVariations.forEach((variation) => {
            variation.variation_elements.forEach((element) => {
              if (element.color?.id === response.payload.data.id) {
                if (
                  JSON.stringify(element.color) !==
                  JSON.stringify(response.payload.data)
                ) {
                  variation.isFetching = true;
                  if (variation.id === state.activeVariation?.id) {
                    state.activeVariation.isFetching = true;
                  }
                }
              }
            });
          });
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.getMockupVariations.matchFulfilled,
        (state, response) => {
          state.mockupVariations = response.payload.data;
          if (state.activeVariation === null) {
            state.activeVariation = response.payload.data[0];
          }
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.createMockupVariations.matchFulfilled,
        (state, response) => {
          response.payload.data.forEach((createdVariation) => {
            if (
              createdVariation.export_path === null &&
              !state.mockupVariations.some(
                (mv) => mv.id === createdVariation.id
              ) // this is maybe not needed? if backend fix bug
            ) {
              createdVariation.isFetching = true;
              state.mockupVariations.push(createdVariation);
            } else {
              state.mockupVariations.forEach((variation) => {
                if (createdVariation.id === variation.id) {
                  if (
                    JSON.stringify(createdVariation.variation_elements) !==
                    JSON.stringify(variation.variation_elements)
                  ) {
                    variation.isFetching = true;
                    if (variation.id === state.activeVariation?.id) {
                      state.activeVariation.isFetching = true;
                    }
                    variation.variation_elements = JSON.parse(
                      JSON.stringify(createdVariation.variation_elements)
                    );
                  }
                }
              });
            }
          });
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.getSingleMockupVariation.matchFulfilled,
        (state, response) => {
          let variation = state.mockupVariations.find(
            (variation) => variation.id == response.payload.data.id
          );
          if (variation) {
            variation.variation_elements = JSON.parse(
              JSON.stringify(response.payload.data.variation_elements)
            );
            variation.export_path = response.payload.data.export_path;
            variation.isFetching = false;
            if (variation.id === state.activeVariation?.id) {
              state.activeVariation.isFetching = false;
              state.activeVariation.export_path =
                response.payload.data.export_path;
            }
          }
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.createColorPreview.matchFulfilled,
        (state, response) => {
          state.colorPreviewVariation = response.payload.data;
          state.colorPreviewVariationIsFetching = false;
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.generateSingleMockupVariation.matchPending,
        (state, response) => {
          const requestArgs = response.meta.arg.originalArgs;
          if (state.activeSmartObject.print_area === 1) {
            state.activeVariation.isFetching = true;
            state.mockupVariations.forEach((variation) => {
              variation.isFetching = true;
              if (variation.id === requestArgs.mockup_variation_id) {
                variation.isInitiator = true;
              }
            });
          } else {
            state.mockupVariations.forEach((variation) => {
              variation.variation_elements.forEach((element) => {
                if (element.asset?.id === requestArgs.asset_id) {
                  variation.isFetching = true;
                  if (variation.id === requestArgs.mockup_variation_id) {
                    state.activeVariation.isFetching = true;
                    variation.isInitiator = true;
                  }
                }
              });
            });
          }
        }
      )
      .addMatcher(
        psdEngineApi.endpoints.generateSingleMockupVariation.matchFulfilled,
        (state, response) => {
          const requestArgs = response.meta.arg.originalArgs;

          // do variation
          let variation = state.mockupVariations.find(
            (variation) => variation.id == response.payload.data.id
          );
          if (variation) {
            variation.variation_elements = JSON.parse(
              JSON.stringify(response.payload.data.variation_elements)
            );
            variation.export_path = response.payload.data.export_path;
            variation.isFetching = false;
            variation.isInitiator = false;
            if (variation.id === state.activeVariation?.id) {
              state.activeVariation.isFetching = false;
              state.activeVariation.export_path =
                response.payload.data.export_path;
            }
          }

          // do asset
          const updatedAsset = response.payload.data.variation_elements.find(
            (ve) => ve.asset_id === requestArgs.asset_id
          )?.asset as DesignAsset;
          state.activeDesignAsset = updatedAsset;

          state.designAssets[state.activeSmartObject.id] = state.designAssets[
            state.activeSmartObject.id
          ].map((asset) => {
            if (asset.id === requestArgs.asset_id) {
              return updatedAsset;
            } else {
              return asset;
            }
          });
        }
      );

    // .addMatcher(
    //   psdEngineApi.endpoints.updateSmartObject.matchFulfilled,
    //   (state, response) => {
    //     state.activeSmartObject = response.payload.data;

    //     state.smartObjects = state.smartObjects.map((smartObject) =>
    //       smartObject.id === response.payload.data.id
    //         ? (smartObject = JSON.parse(
    //             JSON.stringify(response.payload.data)
    //           ))
    //         : smartObject
    //     );

    //     if (response.payload.data.print_area === 1) {
    //       state.mockupVariations.forEach((variation) => {
    //         variation.isFetching = true;
    //         if (variation.id === state.activeVariation?.id) {
    //           state.activeVariation.isFetching = true;
    //         }
    //       });
    //     }
    //   }
    // );
  },
});

export default collectionSlice.reducer;

export const {
  resetCanvasState,
  setActiveSmartObject,
  setActiveColorCombination,
  setActiveDesignAsset,
  setDummyActiveColor,
  setColorPreviewVariation,
  resetCollectionState,
  setColorPreviewVariationState,
  setActiveVariation,
  setIsCreatingVariations,
  setDesignAssets,
  setSelectedHexColor,
  updateActiveSmartObject,
  setColorCombinationsContainerHeight,
  setIsMockupLoading,
} = collectionSlice.actions;
