import { Box, Text, Flex, TextField, ScrollArea } from '@radix-ui/themes';
import '../editor.scss';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setActiveView } from '@/redux/slices/editor';
import {
  setActiveVariation,
  setColorPreviewVariation,
  setColorPreviewVariationState,
  setSelectedHexColor,
} from '@/redux/slices/collection';
import Colorful from '@uiw/react-color-colorful';
import { useCallback, useEffect, useState } from 'react';
import Slider from '@uiw/react-color-slider';
import { PPColors } from './PrintfulAndPrintifyColors';
import { psdEngineApi } from '@/services/psdEngine';
import { debounce } from 'debounce';
import { useMediaQuery } from 'react-responsive';
import { SinglePreview } from '../../view-wrapper/components/SinglePreview';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useFeatureFlagEnabled } from 'posthog-js/react';

interface IFormInput {
  hex: string;
}

export const ColorVariant = () => {
  const dispatch = useAppDispatch();
  const { activeView } = useAppSelector((state) => state.editorReducer);
  const {
    activeSmartObject,
    colorCombinations,
    activeColorCombination,
    mockupVariations,
    activeVariation,
    mockup,
  } = useAppSelector((state) => state.collectionReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const isAppPro = useFeatureFlagEnabled('app-pro');
  const [addColor] = psdEngineApi.useAddColorCombinationMutation();
  const [updateColor] = psdEngineApi.useUpdateColorCombinationMutation();
  const [removeColor] = psdEngineApi.useDeleteColorVariationMutation();
  const [createMockupVariations] =
    psdEngineApi.useCreateMockupVariationsMutation();

  const [printfulAndPrintifyColors, setPrintfulAndPrintifyColors] = useState<
    string[]
  >([...PPColors]);

  const [createColorPreview] = psdEngineApi.useCreateColorPreviewMutation();

  const [hex, setHex] = useState<string>(
    colorCombinations[activeSmartObject.id].find(
      (cc) => cc.id === activeColorCombination?.id
    )?.hex_code || '#FFF'
  );

  useEffect(() => {
    setValue('hex', hex);

    if (
      mockupVariations.length === 1 &&
      !mockupVariations[0].variation_elements.find(
        (ve) => ve.smart_object_id === activeSmartObject.id
      )?.asset_id &&
      !mockupVariations[0].variation_elements.find(
        (ve) => ve.smart_object_id === activeSmartObject.id
      )?.color_id
    ) {
      if (hex !== '#FFF') {
        getColorThumbnailPreview(hex, isAppPro!);
      } else {
        if (
          mockupVariations[0].variation_elements.every(
            (ve) => ve.asset_id && ve.color_id
          )
        ) {
          dispatch(setColorPreviewVariation(mockup.thumbnail));
          dispatch(setColorPreviewVariationState(false));
        } else {
          dispatch(setColorPreviewVariation(activeVariation.export_path));
        }
      }
    } else {
      if (activeVariation?.export_path) {
        if (
          activeVariation.variation_elements.find(
            (ve) => ve.color?.hex_code === hex
          )
        ) {
          dispatch(setColorPreviewVariation(activeVariation.export_path));
        } else {
          if (hex !== '#FFF') {
            getColorThumbnailPreview(hex, isAppPro!);
          } else {
            dispatch(setColorPreviewVariation(activeVariation.export_path));
            dispatch(setColorPreviewVariationState(false));
          }
        }
      } else {
        if (hex !== '#FFF') {
          getColorThumbnailPreview(hex, isAppPro!);
        } else {
          dispatch(setColorPreviewVariation(mockup.thumbnail));
        }
      }
    }
  }, [hex]);

  const goBack = () => {
    dispatch(setActiveView('preview'));
  };

  const addNewColorVariant = async () => {
    const addedColor = await addColor({
      smart_object_id: activeSmartObject?.id,
      hex_code: hex,
    }).unwrap();
    const createdVariations = await createMockupVariations({
      mockup_id: mockup?.id,
      smart_object_id: activeSmartObject.id,
      color_id: addedColor.data.id,
    }).unwrap();
    dispatch(
      setActiveVariation(
        createdVariations.data[createdVariations.data.length - 1]
      )
    );
    goBack();
  };

  const updateColorVariant = async () => {
    await updateColor({
      id: activeColorCombination?.id,
      smart_object_id: activeSmartObject?.id,
      hex_code: hex,
    });
    goBack();
  };

  const removeColorVariant = async () => {
    await removeColor({ id: activeColorCombination?.id }).unwrap();
    goBack();
  };

  const getNumerOfAssociatedVariations = () => {
    const associatedVariations = mockupVariations.filter((variation) => {
      return variation.variation_elements.some(
        (element) => element.color_id === activeColorCombination?.id
      );
    });

    return associatedVariations.length;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IFormInput>({
    defaultValues: {
      hex:
        colorCombinations[activeSmartObject.id].find(
          (cc) => cc.id === activeColorCombination?.id
        )?.hex_code || '#FFF',
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => hexSubmit(data);

  const hexSubmit = async (data: IFormInput) => {
    try {
      setHex(data.hex);
      dispatch(setSelectedHexColor(data.hex));
    } catch (error: any) {
      console.log(error.data);
    }
  };

  useEffect(() => {
    if (
      colorCombinations[activeSmartObject.id].find(
        (cc) => cc.id === activeColorCombination?.id
      )?.hex_code !== hex
    ) {
      getColorThumbnailPreview(hex, isAppPro!);
    }
  }, [hex]);

  const getColorThumbnailPreview = useCallback(
    debounce(async (hex: string, isAppPro: boolean) => {
      dispatch(setColorPreviewVariationState(true));
      await createColorPreview({
        smart_object_id: activeSmartObject.id,
        hex_code: hex,
        mockup_variation_id: activeVariation?.id || undefined,
        // subscription_type_id: isAppPro ? 2 : 1,
        subscription_type_id: 2,
      });
      dispatch(setColorPreviewVariationState(false));
    }, 150),
    []
  );

  // const getColorThumbnailPreview = (hex: string) => {
  //   createColorPreview({
  //     smart_object_id: activeSmartObject.id,
  //     hex_code: hex !== '#FFF' ? hex : '' || undefined,
  //     mockup_variation_id: activeVariation?.id || undefined,
  //     subscription_type_id: isAppPro ? 2 : 1,
  //   });
  // };

  return (
    <Flex className="color-variant" direction={'column'} align={'start'}>
      <ScrollArea
        type="hover"
        scrollbars="vertical"
        style={{
          height: '100vh',
        }}
      >
        {isMobile && activeView === 'update-color' && (
          <Box px={'4'}>
            <SinglePreview />
          </Box>
        )}
        <Flex
          className="color-picker-wrapper"
          direction={'column'}
          gap={'2'}
          p={'4'}
        >
          <Flex gap={'2'} className="previews">
            <Flex className="color-pickers" direction={'column'} gap={'2'}>
              <Colorful
                color={hex || '#0587f0'}
                disableAlpha={true}
                onChange={(color) => {
                  setHex(color.hex);
                  dispatch(setSelectedHexColor(color.hex));
                }}
              />
            </Flex>
          </Flex>
          <Box mb={'5'}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              onChange={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '400px',
              }}
            >
              <label>
                <TextField.Root
                  placeholder="#f0fCCC"
                  {...register('hex', {
                    required: {
                      value: true,
                      message: 'Hex field is required',
                    },
                    pattern: {
                      value: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
                      message: `Entered value is not valid hex format`,
                    },
                  })}
                >
                  <TextField.Slot>
                    <Text weight={'medium'} size={'2'}>
                      HEX
                    </Text>
                  </TextField.Slot>
                </TextField.Root>
                {errors.hex && (
                  <Text role="alert" size={'1'} color="red">
                    {errors.hex.message}
                  </Text>
                )}
              </label>
            </form>
          </Box>

          <Box className="previews">
            <Text size={'2'} weight={'medium'}>
              Shades
            </Text>
            <Flex
              className="color-pickers"
              direction={'column'}
              gap={'2'}
              mt={'4'}
            >
              <Slider
                color={hex}
                onChange={(color) => {
                  setHex(color.hex);
                  dispatch(setSelectedHexColor(color.hex));
                }}
              />
            </Flex>
          </Box>
        </Flex>
        <Flex className="vendor-colors-wrapper" wrap={'wrap'} gap={'4'} p={'4'}>
          <Text weight={'bold'} size={'2'}>
            Printful & Printify merch colors
          </Text>
          <Flex className="vendor-colors" wrap={'wrap'} gap={'2'}>
            {printfulAndPrintifyColors?.map((ppc) => (
              <Flex
                key={ppc}
                className={`color-item ${ppc === hex && 'active'}`}
                align={'center'}
                justify={'center'}
                onClick={() => {
                  setHex(ppc);
                  dispatch(setSelectedHexColor(ppc));
                }}
              >
                <Box className="color" style={{ backgroundColor: ppc }}></Box>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};
