import { Navigate, createBrowserRouter } from 'react-router-dom';
import { PublicRoute } from './PublicRoute';
import { ProtectedRoute } from './ProtectedRoute';
import { NotFound } from '@/features/layout/NotFound';
import { Login } from '@/features/screens/auth/Login';
import { Register } from '@/features/screens/auth/Register';
import { Workspace } from '@/features/screens/workspace/Workspace';
import { Collection } from '@/features/screens/collection/Collection';
import { AutoAuth } from '@/features/screens/auth/AutoAuth';
import { AppHeader } from '@/features/layout/header/AppHeader';
import { Shop } from '@/features/screens/workspace/components/shop/Shop';
import { Product } from '@/features/screens/workspace/components/product/Product';
import { ForgotPassword } from '@/features/screens/auth/ForgotPassword';
import { ResetPassword } from '@/features/screens/auth/ResetPassword';
import { ForgotPasswordSuccess } from '@/features/screens/auth/ForgotPasswordSuccess';

export enum routes {
  APP = '/',
  ACCOUNT = '/account',
  CUSTOM_MOCKUPS = '/custom-mockups',
  PUBLIC_LIBRARY = '/mockup-library',
  DESIGNS = '/designs',
  BULK_TEMPLATES = '/my-templates',
  BULK_TEMPLATES_COLLECTION = '/my-templates/:collectionId',
  API_DASHBOARD = '/dashboard-api',
  INTEGRATIONS = '/integrations',
  SHOPS = '/shops',
  SHOP = '/shops/:shopId',
  PRODUCTS = '/shop-products/:shopId',
  PRODUCT = '/shop-products/:shopId/product/:productId',
  COLLECTION = '/mockup/:collectionId',
  COLLECTION_SMART_OBJECT = '/mockup/:collectionId/smart-object/:smartObjectId',
  AUTO_AUTH = '/auth',
  LOGIN = '/login',
  REGISTER = '/register',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/password-reset',
  FORGOT_PASSWORD_SUCCESS = '/forgot-password/success',
}

export const AppRoutes = createBrowserRouter([
  {
    path: routes.LOGIN,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: routes.REGISTER,
    element: (
      <PublicRoute>
        <Register />
      </PublicRoute>
    ),
  },
  {
    path: routes.FORGOT_PASSWORD,
    element: (
      <PublicRoute>
        <ForgotPassword />
      </PublicRoute>
    ),
  },
  {
    path: routes.FORGOT_PASSWORD_SUCCESS,
    element: (
      <PublicRoute>
        <ForgotPasswordSuccess />
      </PublicRoute>
    ),
  },
  {
    path: routes.RESET_PASSWORD,
    element: (
      <PublicRoute>
        <ResetPassword />
      </PublicRoute>
    ),
  },
  {
    path: routes.AUTO_AUTH,
    element: (
      <PublicRoute>
        <AutoAuth />
      </PublicRoute>
    ),
  },
  {
    path: routes.APP,
    element: <Navigate replace to={routes.PUBLIC_LIBRARY} />,
  },

  {
    element: <AppHeader />,
    children: [
      {
        path: routes.APP,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.ACCOUNT,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.PUBLIC_LIBRARY,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.CUSTOM_MOCKUPS,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.DESIGNS,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.BULK_TEMPLATES,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.BULK_TEMPLATES_COLLECTION,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.API_DASHBOARD,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.INTEGRATIONS,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.SHOPS,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.SHOP,
        element: (
          <ProtectedRoute>
            <Shop />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.PRODUCTS,
        element: (
          <ProtectedRoute>
            <Workspace />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.PRODUCT,
        element: (
          <ProtectedRoute>
            <Product />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.COLLECTION,
        element: (
          <ProtectedRoute>
            <Collection />
          </ProtectedRoute>
        ),
      },

      {
        path: routes.COLLECTION_SMART_OBJECT,
        element: (
          <ProtectedRoute>
            <Collection />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
