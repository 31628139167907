import { Flex, Box, Button } from '@radix-ui/themes';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as Loader } from '@/assets/loader.svg';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { psdEngineApi } from '@/services/psdEngine';
import { toast } from 'react-toastify';

interface IMainImagePreview {
  image: string;
  isFetching: boolean;
  height?: string;
  isVariationPreview: boolean;
}

export const MainImagePreview = ({
  image,
  isFetching,
  height,
  isVariationPreview,
}: IMainImagePreview) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isAppPro = useFeatureFlagEnabled('app-pro');
  const { activeVariation } = useAppSelector(
    (state) => state.collectionReducer
  );
  const [getSingleUrl, { isLoading: isGettingSingleUrl }] =
    psdEngineApi.useGetSingleUrlMutation();
  const programmaticTools = useFeatureFlagEnabled('programmatic-tools');

  return (
    <>
      {image !== null && image !== undefined && (
        <Flex
          className="main-image-preview"
          align={'center'}
          justify={'center'}
        >
          {/* {mockup?.subscription_type_id === 2 && (
            <ProCrownIcon className="pro-icon" width="24px" height="24px" />
          )} */}
          {/* {!isAppPro && (
            <Flex
              className="watermark-button"
              onClick={() => {
                dispatch(setIsAppPaymentPopupOpen(true));
                captureEvent('Upgrade your plan modal viewed', {
                  source: 'Watermark',
                });
              }}
            ></Flex>
          )} */}

          {programmaticTools && (
            <Button
              style={{
                position: 'absolute',
                top: '-40px',
                left: '0',
                zIndex: '100',
                background: 'linear-gradient(135deg, #7b2ff7 0%, #f107a3 100%)',
              }}
              loading={isGettingSingleUrl}
              onClick={async () => {
                const url = await getSingleUrl({
                  format: 'WEBP',
                  max_size: 1500,
                  mockup_variation_id: activeVariation.id,
                  subscription_type_id: 2,
                }).unwrap();

                navigator.clipboard.writeText(url.data);
                toast.success('Variation URL copied to clipboard.', {
                  toastId: 'var_url_copied_clipboard',
                  position: 'bottom-right',
                  autoClose: 3000,
                });
              }}
            >
              Copy variation URL
            </Button>
          )}

          {isFetching && <Loader className="animate-spin" />}
          <LazyLoadImage
            height={'auto'}
            width={'auto'}
            style={{
              objectFit: 'contain',
              filter: isFetching ? 'blur(3px)' : 'blur(0)',
              height: isMobile ? '30vh' : height || '100%',
            }}
            effect="blur"
            src={image}
            delayTime={0}
            placeholderSrc={image}
          />
        </Flex>
      )}
      {(image === null || image === undefined) && (
        <Box className="swiper-slide-transform" width={'100%'} height={'100%'}>
          <Skeleton
            width={'100%'}
            height={'100%'}
            className="swiper-slide-skeleton"
          />
        </Box>
      )}
    </>
  );
};
