import './workspace.scss';
import { Flex } from '@radix-ui/themes';
import { LeftSideMenu } from './components/left-side-menu/LeftSideMenu';
import { ViewWrapper } from './components/view-wrapper/ViewWrapper';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

export type pageSlug =
  | 'custom-mockups'
  | 'my-templates'
  | 'mockup-library'
  | 'dashboard-api'
  | 'account'
  | 'early-access-features'
  | 'submit-feedback'
  | 'help-center'
  | 'integrations'
  | 'designs'
  | 'shops'
  | 'shop'
  | 'shop-products'
  | 'product';

export const Workspace = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState<pageSlug>('my-templates');
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  useEffect(() => {
    setActiveSection(location.pathname.split('/')[1] as pageSlug);
  }, [location.pathname]);

  return (
    <Flex direction={isMobile ? 'column' : 'row'} className="workspace-wrapper">
      <LeftSideMenu activeSection={activeSection} />
      <ViewWrapper activeSection={activeSection} />
    </Flex>
  );
};

