import { Flex } from '@radix-ui/themes';
import '../view-wrapper.scss';
import { useAppSelector } from '@/hooks';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { MainImagePreview } from './MainImagePreview';

interface IAiBackgroundPreview {}

export const AiBackgroundPreview = ({}: IAiBackgroundPreview) => {
  const { mockup, activeVariation } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { aiBackgroundImage, isGeneratingPreviewImage, isImageGenerated } =
    useAppSelector((state) => state.aiBackgroundsReducer);

  return (
    <Flex className="single-color-preview" align={'center'} justify={'center'}>
      <MainImagePreview
        image={
          (isImageGenerated
            ? aiBackgroundImage
            : activeVariation?.export_path) || mockup?.thumbnail
        }
        isFetching={isGeneratingPreviewImage}
        height={'80vh'}
        isVariationPreview={false}
      />
    </Flex>
  );
};
