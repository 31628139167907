import { Helmet } from 'react-helmet-async';
import './integrations.scss';
import { Button, Card, Flex, Link, ScrollArea, Text } from '@radix-ui/themes';
import { ApiPaymentPopup } from '../api-dashboard/components/ApiPaymentPopup';
import { ReactComponent as ShopifyIcon } from '@/assets/icons/shopify.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';

export const Integrations = () => {
  return (
    <Flex className="view-wrapper-integrations" direction={'column'} gap={'4'}>
      <Helmet>
        <title>Dynamic Mockups | Integrations</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex className="header" mt={'5'} mx={'5'} mb={'4'} align={'center'}>
        <Text weight={'bold'} size={'5'}>
          Integrations
        </Text>
      </Flex>

      <ScrollArea
        className="scroll-area-wrapper"
        type="hover"
        scrollbars="vertical"
        style={{ height: 'calc(100vh - 77px)' }}
      >
        <Flex className="view-wrapper" direction={'column'} gap={'3'} px={'5'}>
          <Flex direction={'column'} align={'start'} gap={'4'}>
            <Flex
              direction={'row'}
              align={'center'}
              justify={'start'}
              width={'100%'}
              wrap={'wrap'}
              gap={'2'}
              className="items-card-wrapper"
            >
              <Card className="item-card" size={'5'}>
                <Flex direction={'column'}>
                  <Flex
                    className="testmilos"
                    align={'center'}
                    justify={'between'}
                  >
                    <ShopifyIcon />
                    <Button
                      event-tracker-id="connect-shopify-integration"
                      variant="outline"
                      color="gray"
                      className="action"
                    >
                      <PlusIcon className="icon black" />
                      <Text size="1" weight="medium">
                        Connect
                      </Text>
                    </Button>
                  </Flex>
                  <Text
                    className="title"
                    size={'3'}
                    weight={'bold'}
                    mb={'2'}
                    mt={'4'}
                  >
                    Shoppify
                  </Text>
                  <Text size={'2'}>
                    Connect your Shopify store and automatically sync products
                    and mockups avoiding the need for repetitive and manual
                    updates.
                  </Text>
                </Flex>
              </Card>
            </Flex>
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

