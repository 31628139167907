import { Helmet } from 'react-helmet-async';
import './mockup-templates.scss';
import { Box, Button, Flex, ScrollArea, Tabs, Text } from '@radix-ui/themes';
import { useEffect, useRef, useState } from 'react';
import { UploadPsdDialog } from './components/UploadPsdDialog';
import { PhotoshopFiles } from './components/view-wrapper/PhotoshopFiles';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import posthog from 'posthog-js';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { psdManagementApi } from '@/services/psdManagement';
import { PsdCategory } from '@/services/types';
import { ReactComponent as PhotoshopIcon } from '@/assets/icons/photoshop-linear.svg';
import useCaptureEvent from '@/hooks/useCaptureEvent';

export const CustomMockups = () => {
  const dispatch = useAppDispatch();
  const uploadPsdDialog = useRef();
  const captureEvent = useCaptureEvent();
  const { photoshopFiles, photoshopFilesCategories } = useAppSelector(
    (state) => state.psdManagementReducer
  );
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const getPrivatePsds = psdManagementApi.useGetPrivatePsdsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });
  const isAppPro = useFeatureFlagEnabled('app-pro');

  const [categories, setCategories] = useState<PsdCategory[]>([]);
  const [tabValue, setTabValue] = useState<string>(
    categories[0]?.id.toString()
  );
  const psdCategories = psdManagementApi.useGetPsdCategoriesQuery();

  const onTabChange = (id: string) => {
    const categories = psdCategories.data?.data;
    if (categories) {
      setTabValue(id.toString());
    }
  };

  useEffect(() => {
    if (psdCategories.data?.data) {
      const data = psdCategories.data?.data.filter((cat) =>
        photoshopFilesCategories.includes(cat.id)
      );
      setCategories(data);
    }
  }, [psdCategories, photoshopFilesCategories, photoshopFiles]);

  useEffect(() => {
    if (parseInt(tabValue) !== categories[0]?.id) {
      if (!categories.find((cat) => cat.id === parseInt(tabValue))) {
        setTabValue(categories[0]?.id.toString());
      }
    }
  }, [tabValue, categories]);

  return (
    <Flex
      className="view-wrapper-mockup-templates"
      direction={'column'}
      gap={'4'}
    >
      <Helmet>
        <title>Dynamic Mockups | Custom Mockups</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex
        className="header"
        mt={'5'}
        mx={'5'}
        align={'center'}
        justify={'between'}
      >
        <Text weight={'bold'} size={'5'}>
          Custom Mockups
        </Text>
        <UploadPsdDialog ref={uploadPsdDialog} />
        {photoshopFiles.length > 0 && (
          <Button
            event-tracker-id="upload-psd-button"
            onClick={() => {
              if (isAppPro) {
                (uploadPsdDialog?.current as any)?.openDialog();
                captureEvent('Upload PSD Mockup modal viewed');
              } else {
                dispatch(setIsAppPaymentPopupOpen(true));
              }
            }}
            color="gray"
            variant="outline"
          >
            <PlusIcon className="icon black" />
            <Text size="1" weight="medium">
              Upload PSD Mockup
            </Text>
          </Button>
        )}
      </Flex>

      {!getPrivatePsds.isLoading && getPrivatePsds.data?.data.length !== 0 && (
        <Tabs.Root value={tabValue} onValueChange={(val) => onTabChange(val)}>
          <Box px={'6'}>
            <Tabs.List>
              {categories.map((category) => (
                <Tabs.Trigger key={category.id} value={category.id.toString()}>
                  {category.name} (
                  {
                    photoshopFiles?.filter(
                      (pf) => pf.psd_category_id === category.id
                    )?.length
                  }
                  )
                </Tabs.Trigger>
              ))}
            </Tabs.List>
          </Box>

          <Box mt={'4'}>
            {categories.map((category) => (
              <Tabs.Content key={category.id} value={category.id.toString()}>
                <PhotoshopFiles
                  categoryId={category.id}
                  resetTabs={() => setTabValue('0')}
                />
              </Tabs.Content>
            ))}
          </Box>
        </Tabs.Root>
      )}
      {!getPrivatePsds.isLoading && getPrivatePsds.data?.data.length === 0 && (
        <ScrollArea
          className="scroll-area-wrapper"
          type="hover"
          scrollbars="vertical"
          style={{ height: 'calc(100vh - 69px)' }}
        >
          <Flex
            direction={'column'}
            align={'center'}
            justify={'center'}
            gap={'4'}
            className="empty-page-state"
          >
            <Flex align={'center'} justify={'center'} className="cercle-wrap">
              <PhotoshopIcon className="svg-icon photoshop" />
            </Flex>
            <Text size="2" weight="regular" align={'center'}>
              Upload your custom Photoshop Template and bulk create Mockups
            </Text>

            <Button
              variant="solid"
              event-tracker-id="upload-psd-button"
              onClick={() => {
                if (isAppPro) {
                  (uploadPsdDialog?.current as any)?.openDialog();
                  captureEvent('Upload PSD Mockup modal viewed');
                } else {
                  captureEvent('Upgrade your plan modal viewed', {
                    source: 'Upload psd mockup button',
                  });
                  dispatch(setIsAppPaymentPopupOpen(true));
                }
              }}
            >
              <PlusIcon className="icon white" />

              <Text size="1" weight="medium">
                Upload PSD Mockup
              </Text>
            </Button>
          </Flex>
        </ScrollArea>
      )}
    </Flex>
  );
};
