import { mockupCollectionsApi } from '@/services/mockupCollections';
import { MockupCollection } from '@/services/types';
import { createSlice } from '@reduxjs/toolkit';

interface IMockupCollectionsState {
  activeMockupCollectionId: number;
  mockupCollections: MockupCollection[];
}

const initialState: IMockupCollectionsState = {
  activeMockupCollectionId: 0,
  mockupCollections: [],
};

export const mockupCollectionsSlice = createSlice({
  initialState,
  name: 'mockupCollectionsSlice',
  reducers: {
    setActiveMockupCollectionId(state, { payload }) {
      state.activeMockupCollectionId = payload;
    },
    resetEditorState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      mockupCollectionsApi.endpoints.getMockupCollections.matchFulfilled,
      (state, response) => {
        state.mockupCollections = response.payload.data;
      }
    );
  },
});

export default mockupCollectionsSlice.reducer;

export const { setActiveMockupCollectionId } = mockupCollectionsSlice.actions;
