import { CheckCircledIcon, Cross1Icon } from '@radix-ui/react-icons';
import {
  Dialog,
  Button,
  Flex,
  Grid,
  Box,
  Text,
  ScrollArea,
  Badge,
  Tabs,
} from '@radix-ui/themes';
import { ReactComponent as DCLogo } from '@/assets/dm-logo-vector-api-payments.svg';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/checkmark.svg';
import './apiPpaymentPopup.scss';
import { ReactNode, useEffect, useState } from 'react';
import StripeCheckout from '@/features/components/StripeCheckout';
import { useMediaQuery } from 'react-responsive';
import { useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IApiPaymentPopup {
  children: ReactNode;
}

export interface MonthlyPlan {
  amount: string;
  discountedPrice?: string;
  price?: string;
  fullPrice?: string;
  discountedMonthlyPrice?: string;
  discount?: string;
  saved?: string;
  selected: boolean;
  stripeLink: string;
}

export const monthlyPlansDataDEV = [
  {
    amount: '100',
    price: '10',
    selected: false,
    stripeLink: 'https://buy.stripe.com/test_aEUcNCaxIcbV9jyeUV',
  },
  {
    amount: '200',
    price: '20',
    selected: false,
    stripeLink: 'https://buy.stripe.com/test_aEUeVKeNYa3N2Va8wy',
  },
];

export const yearlyPlansDataDEV = [
  {
    amount: '100',
    discountedPrice: '75',
    fullPrice: '100',
    discountedMonthlyPrice: '6.25',
    discount: '25%',
    saved: '25',
    selected: false,
    stripeLink: 'https://buy.stripe.com/test_4gw00QbBMb7R67mbIL',
  },
  {
    amount: '200',
    discountedPrice: '150',
    fullPrice: '200',
    discountedMonthlyPrice: '12.5',
    discount: '25%',
    saved: '50',
    selected: false,
    stripeLink: 'https://buy.stripe.com/test_3cs5la9tEb7RfHW7sw',
  },
];

export const monthlyPlansData = [
  {
    amount: '500',
    price: '49',
    selected: false,
    stripeLink: 'https://buy.stripe.com/7sIcQqb7N0K6dgIdQQ',
  },
  {
    amount: '1.000',
    price: '89',
    selected: false,
    stripeLink: 'https://buy.stripe.com/cN27w62Bh2Se1y0fYZ',
  },
  {
    amount: '3.000',
    price: '249',
    selected: false,
    stripeLink: 'https://buy.stripe.com/28o8Aaa3J8cyekM002',
  },
  {
    amount: '10.000',
    price: '749',
    selected: false,
    stripeLink: 'https://buy.stripe.com/fZedUua3JeAW1y06or',
  },
  {
    amount: '50.000',
    price: '3,499',
    selected: false,
    stripeLink: 'https://buy.stripe.com/eVa4jU4JpboKgsU5ko',
  },
];

export const yearlyPlansData = [
  {
    amount: '500',
    discountedPrice: '441',
    fullPrice: '588',
    discountedMonthlyPrice: '37',
    discount: '25%',
    saved: '147',
    selected: false,
    stripeLink: 'https://buy.stripe.com/4gw2bM1xdgJ4dgIdR4',
  },
  {
    amount: '1.000',
    discountedPrice: '801',
    fullPrice: '1068',
    discountedMonthlyPrice: '67',
    discount: '25%',
    saved: '267',
    selected: false,
    stripeLink: 'https://buy.stripe.com/14kdUu2Bh8cy0tWfZd',
  },
  {
    amount: '3.000',
    discountedPrice: '2,241',
    fullPrice: '2,988',
    discountedMonthlyPrice: '187',
    discount: '25%',
    saved: '747',
    selected: false,
    stripeLink: 'https://buy.stripe.com/14k9EeejZcsO5Og9AQ',
  },
  {
    amount: '10.000',
    discountedPrice: '6,741',
    fullPrice: '8,988',
    discountedMonthlyPrice: '562',
    discount: '25%',
    saved: '2,247',
    selected: false,
    stripeLink: 'https://buy.stripe.com/cN217Ib7N78u6Sk5kB',
  },
  {
    amount: '50.000',
    discountedPrice: '31,491',
    fullPrice: '41,988',
    discountedMonthlyPrice: '2,625',
    discount: '25%',
    saved: '10,497',
    selected: false,
    stripeLink: 'https://buy.stripe.com/dR603E0t98cy6SkfZg',
  },
];

export const ApiPaymentPopup = ({ children }: IApiPaymentPopup) => {
  const captureEvent = useCaptureEvent();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { userRenders } = useAppSelector((state) => state.paymentsReducer);

  const [monthlyPlans, setMonthlyPlans] = useState<MonthlyPlan[]>(
    !!parseInt(IS_PRODUCTION) ? monthlyPlansData : monthlyPlansDataDEV
  );
  const [yearlyPlans, setYearlyPlans] = useState<MonthlyPlan[]>(
    !!parseInt(IS_PRODUCTION) ? yearlyPlansData : yearlyPlansDataDEV
  );

  useEffect(() => {
    if (currentUser) {
      let updatedMonthlyPlans = monthlyPlans.map((plan) => ({
        ...plan,
        stripeLink: plan.stripeLink + '?prefilled_email=' + currentUser?.email,
      }));
      setMonthlyPlans(updatedMonthlyPlans);

      let updatedYearlyPlans = yearlyPlans.map((plan) => ({
        ...plan,
        stripeLink: plan.stripeLink + '?prefilled_email=' + currentUser?.email,
      }));
      setYearlyPlans(updatedYearlyPlans);
    }
  }, [currentUser]);

  // useEffect(() => {
  //   if (userRenders) {
  //     let monthlyPlansCopy = [...monthlyPlans];
  //     let yearlyPlansCopy = [...yearlyPlans];
  //     let count = 0;
  //     for (let plan of monthlyPlansCopy) {
  //       if (
  //         plan.amount !== userRenders.plan.toString() &&
  //         userRenders?.interval === 'monthly' &&
  //         count < 1
  //       ) {
  //         plan.selected = true;
  //         count++;
  //       } else {
  //         plan.selected = false;
  //       }
  //     }

  //     for (let plan of yearlyPlansCopy) {
  //       if (
  //         plan.amount !== userRenders.plan.toString() &&
  //         userRenders?.interval === 'yearly' &&
  //         count < 1
  //       ) {
  //         plan.selected = true;
  //         count++;
  //       } else {
  //         plan.selected = false;
  //       }
  //     }

  //     setMonthlyPlans(monthlyPlansCopy);
  //     setYearlyPlans(yearlyPlansCopy);
  //   }
  // }, [userRenders]);

  const getSelectedPlan = () => {
    if (tabValue === 'Monthly') {
      return monthlyPlans.find((plan) => plan.selected);
    } else {
      return yearlyPlans.find((plan) => plan.selected);
    }
  };

  const selectMonthlyPlan = (plan: MonthlyPlan) => {
    if (
      parseInt(plan.amount) === userRenders?.plan &&
      userRenders?.interval === 'monthly'
    ) {
      return;
    }
    const updatedPlans = monthlyPlans.map((item) => ({
      ...item,
      selected: item.amount === plan.amount,
    }));
    captureEvent(
      `Plan ${plan.amount} Monthly Renders, $${plan.price} / month selected`
    );

    setMonthlyPlans(updatedPlans);
  };

  const selectYearlyPlan = (plan: MonthlyPlan) => {
    if (
      parseInt(plan.amount) === userRenders?.plan &&
      userRenders?.interval === 'yearly'
    ) {
      return;
    }
    const updatedPlans = yearlyPlans.map((item) => ({
      ...item,
      selected: item.amount === plan.amount,
    }));
    captureEvent(
      `Plan ${plan.amount} Monthly Renders, $${plan.discountedPrice} / year selected`
    );

    setYearlyPlans(updatedPlans);
  };

  const goToStripePlanCheckout = () => {
    captureEvent('Payment initiated');
    window.location.replace(getSelectedPlan()?.stripeLink || '');
  };

  const [tabValue, setTabValue] = useState<string>('Monthly');

  return (
    <Dialog.Root defaultOpen={false}>
      <Dialog.Trigger>{children}</Dialog.Trigger>

      <Dialog.Content
        aria-describedby={undefined}
        className="api-payment-popup-wrapper"
        style={{ maxWidth: 824, maxHeight: 526, padding: '8px' }}
        onPointerDownOutside={() => captureEvent('API Plans modal closed')}
      >
        <Grid columns={isMobile ? '1fr' : '3fr 3fr'} rows={'1fr'} gap={'2'}>
          <Flex direction={'column'} justify={'between'} p={'5'}>
            <DCLogo style={{ margin: '0 auto' }} />
            <Flex className="title-wrapper" direction={'column'} gap={'4'}>
              <Text weight={'regular'}>API Integration</Text>
              <Text weight={'regular'}>Dynamic Mockups</Text>
              <Text weight={'light'}>
                Bring designs to life with realistic Mockups on your website.
                Use the Public Library or your Photoshop template.
              </Text>
            </Flex>
          </Flex>
          <Flex className="content-wrapper" direction={'column'}>
            <Flex justify={'between'} align={'center'} p={'2'}>
              <Text
                size={'2'}
                weight={'bold'}
                align={'center'}
                style={{ width: '100%' }}
              >
                Choose your Plan
              </Text>
              <Dialog.Close>
                <Cross1Icon
                  cursor={'pointer'}
                  onClick={() => captureEvent('API Plans modal closed')}
                />
              </Dialog.Close>
            </Flex>

            <Tabs.Root
              value={tabValue}
              onValueChange={(val) => {
                captureEvent(`${val} Plan Category viewed`);
                setTabValue(val);
              }}
            >
              <Tabs.List>
                <Tabs.Trigger value="Monthly">Monthly</Tabs.Trigger>
                <Tabs.Trigger value="Yearly">
                  Yearly{' '}
                  <Badge color="green" className="save-badge">
                    SAVE 25%
                  </Badge>
                </Tabs.Trigger>
              </Tabs.List>

              <Box pt="1">
                <Tabs.Content value="Monthly">
                  <ScrollArea
                    type="hover"
                    scrollbars="vertical"
                    style={{ height: '358px' }}
                  >
                    <Flex px={'5'} py={'4'} direction={'column'} gap={'2'}>
                      {monthlyPlans.map((plan) => (
                        <Flex
                          key={plan.amount}
                          className={`pricing-item ${
                            plan.selected ? 'active' : ''
                          } ${
                            parseInt(plan.amount) === userRenders?.plan &&
                            userRenders?.interval === 'monthly'
                              ? 'current-plan'
                              : ''
                          }`}
                          justify={'between'}
                          align={'center'}
                          px={'2'}
                          py={'3'}
                          onClick={() => selectMonthlyPlan(plan)}
                        >
                          <Flex direction={'column'}>
                            <Text size={'1'} weight={'bold'}>
                              {plan.amount} Monthly Renders
                            </Text>
                            <Text
                              size={'1'}
                              weight={'medium'}
                              className="price"
                            >
                              {`$${plan.price}`} / month
                            </Text>
                          </Flex>
                          {plan.selected && <CheckmarkIcon />}
                          {parseInt(plan.amount) === userRenders?.plan &&
                            userRenders?.interval === 'monthly' && (
                              <Badge
                                color="gray"
                                style={{ fontWeight: 'bold' }}
                              >
                                CURRENT PLAN
                              </Badge>
                            )}
                        </Flex>
                      ))}
                    </Flex>
                  </ScrollArea>
                </Tabs.Content>
                <Tabs.Content value="Yearly">
                  <ScrollArea
                    type="hover"
                    scrollbars="vertical"
                    style={{ height: '358px' }}
                  >
                    <Flex px={'5'} py={'4'} direction={'column'} gap={'2'}>
                      {yearlyPlans.map((plan) => (
                        <Flex
                          key={plan.amount}
                          className={`pricing-item ${
                            plan.selected ? 'active' : ''
                          } ${
                            parseInt(plan.amount) === userRenders?.plan &&
                            userRenders?.interval === 'yearly'
                              ? 'current-plan'
                              : ''
                          }`}
                          justify={'between'}
                          align={'center'}
                          px={'2'}
                          py={'3'}
                          onClick={() => selectYearlyPlan(plan)}
                        >
                          <Flex direction={'column'}>
                            <Text size={'1'} weight={'bold'}>
                              {plan.amount} Monthly Renders
                            </Text>
                            <Text
                              size={'1'}
                              weight={'medium'}
                              className="price"
                            >
                              {`$${plan.discountedPrice}`} / year
                            </Text>
                          </Flex>
                          {plan.selected && <CheckmarkIcon />}
                          {parseInt(plan.amount) === userRenders?.plan &&
                            userRenders?.interval === 'yearly' && (
                              <Badge
                                color="gray"
                                style={{ fontWeight: 'bold' }}
                              >
                                CURRENT PLAN
                              </Badge>
                            )}
                        </Flex>
                      ))}
                    </Flex>
                  </ScrollArea>
                </Tabs.Content>
              </Box>
            </Tabs.Root>

            <Box p={'4'}>
              <Button
                color="blue"
                variant="solid"
                style={{ width: '100%' }}
                onClick={() => goToStripePlanCheckout()}
              >
                Subscribe
              </Button>
            </Box>
          </Flex>
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
};
