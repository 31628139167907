import { Helmet } from 'react-helmet-async';
import './shops.scss';
import {
  AlertDialog,
  Badge,
  Box,
  Button,
  Card,
  Flex,
  ScrollArea,
  Skeleton,
  Tabs,
  Text,
} from '@radix-ui/themes';
import { ReactComponent as BulbIcon } from '@/assets/icons/bulb.svg';
import { ReactComponent as ShopifyIcon } from '@/assets/icons/shopify.svg';
import { ReactComponent as WooCommerceIcon } from '@/assets/icons/woocommerce.svg';
import { ReactComponent as EtsyIcon } from '@/assets/icons/etsy.svg';
import { ReactComponent as GearIcon } from '@/assets/icons/gear.svg';
import { ReactComponent as BigCommerceIcon } from '@/assets/icons/bigcommerce.svg';
import { ReactComponent as WixIcon } from '@/assets/icons/wix.svg';
import { ReactComponent as AmazonIcon } from '@/assets/icons/amazon.svg';
import { ReactComponent as EbayIcon } from '@/assets/icons/Ebay.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { toast } from 'react-toastify';

import { useEffect, useState } from 'react';
import posthog from 'posthog-js';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { shopsIntegrationsApi } from '@/services/shopsIntegrations';
import { useAppDispatch, useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IShop {
  id?: number;
  name: string;
  icon: JSX.Element;
  posthogProp: string;
}

export const Shops = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { shopIntegrationPlatforms, activeIntegrations } = useAppSelector(
    (state) => state.shopsIntegrations
  );
  const { currentUser } = useAppSelector((state) => state.userReducer);

  const [searchParams, setSearchParams] = useSearchParams({});
  const [etsyAlert, setEtsyAlert] = useState(false);
  const [noShopErrorMessage, setNoShopErrorMessage] = useState(
    "It looks like you don't have an Etsy shop associated with your account. Please create a shop on Etsy first, then click the 'Connect' button to connect your store and start syncing mockups with your products."
  );

  const etsyShopConnect = useFeatureFlagEnabled('etsy-shop-connect');

  const [isFilteringAvailablePlatforms, setIsFilteringAvailablePlatforms] =
    useState<boolean>(false);
  const [integrationPlatforms, setIntegrationPlatforms] = useState<IShop[]>([
    {
      name: 'Etsy',
      icon: <EtsyIcon />,
      posthogProp: 'early_adopter_etsy_integration',
    },
    {
      name: 'Amazon Merch',
      icon: <AmazonIcon />,
      posthogProp: 'early_adopter_amazonmerch_integration',
    },
    {
      name: 'eBay',
      icon: <EbayIcon />,
      posthogProp: 'early_adopter_ebay_integration',
    },
    {
      name: 'Shopify',
      icon: <ShopifyIcon />,
      posthogProp: 'early_adopter_shopify_integration',
    },

    {
      name: 'WooCommerce',
      icon: <WooCommerceIcon />,
      posthogProp: 'early_adopter_woocommerce_integration',
    },
    {
      name: 'Wix',
      icon: <WixIcon />,
      posthogProp: 'early_adopter_wix_integration',
    },
    {
      name: 'BigCommerce',
      icon: <BigCommerceIcon />,
      posthogProp: 'early_adopter_bigcommerce_integration',
    },
  ]);

  const integrationShopifySubscribed = useFeatureFlagEnabled(
    'integration-shopify'
  );
  const integrationEtsySubscribed = useFeatureFlagEnabled('integration-etsy');
  const integrationWooCommerceSubscribed = useFeatureFlagEnabled(
    'integration-woocommerce'
  );
  const integrationWixSubscribed = useFeatureFlagEnabled('integration-wix');
  const integrationAmazonMmerchSubscribed = useFeatureFlagEnabled(
    'integration-amazonmerch'
  );
  const integrationBigCommerceSubscribed = useFeatureFlagEnabled(
    'integration-bigcommerce'
  );
  const integrationEbaySubscribed = useFeatureFlagEnabled('integration-ebay');

  const captureUserProp = async (eventName: string) => {
    captureEvent('User Applied for Early Access', {
      $set_once: { [eventName]: true },
    });

    await new Promise((resolve) => setTimeout(resolve, 250));
    posthog.reloadFeatureFlags();
    await new Promise((resolve) => setTimeout(resolve, 250));
    posthog.reloadFeatureFlags();
  };

  const [
    availableShopIntegrationPlatforms,
    setAvailableShopIntegrationPlatforms,
  ] = useState<IShop[]>();
  const [soonToComeShops, setSoonToComeShops] = useState<IShop[]>();

  const shopIntegrationPlatformsQuery =
    shopsIntegrationsApi.useGetShopIntegrationPlatformsQuery(null!, {
      refetchOnMountOrArgChange: true,
    });
  shopsIntegrationsApi.useGetActiveIntegrationsQuery(null!, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    filterAvailableShopIntegrationPlatforms();
    filterSoonToComeShopIntegrationPlatforms();
  }, [shopIntegrationPlatforms]);

  const [isConnectingShop, setIsConnectingShop] = useState(false);

  const connectShop = async (id: number | undefined) => {
    setIsConnectingShop(true);
    const url = await dispatch(
      shopsIntegrationsApi.endpoints.getAutorizationUrl.initiate({
        id: id as number,
      })
    ).unwrap();
    captureEvent('Integration Connection Initiated', {
      type: 'Etsy',
    });
    window.location.replace(url.data.authorization_url);
    setIsConnectingShop(false);
  };

  const filterAvailableShopIntegrationPlatforms = () => {
    setIsFilteringAvailablePlatforms(true);
    const platformsNamesSet = new Set(
      shopIntegrationPlatforms.map((shop) => shop.name)
    );

    const filteredShopIntegrationPlatforms = integrationPlatforms.filter(
      (shop) => platformsNamesSet.has(shop.name)
    );

    filteredShopIntegrationPlatforms.forEach((shop) => {
      shopIntegrationPlatforms.forEach((platform) => {
        if (shop.name === platform.name) {
          shop.id = platform.shop_integration_platform_id;
        }
      });
    });

    setAvailableShopIntegrationPlatforms(filteredShopIntegrationPlatforms);
    setIsFilteringAvailablePlatforms(false);
  };

  const filterSoonToComeShopIntegrationPlatforms = () => {
    const platformsNamesSet = new Set(
      shopIntegrationPlatforms.map((shop) => shop.name)
    );
    const filteredShopIntegrationPlatforms = integrationPlatforms.filter(
      (shop) => !platformsNamesSet.has(shop.name)
    );
    setSoonToComeShops(filteredShopIntegrationPlatforms);
  };

  useEffect(() => {
    async function handleConnectionStatus() {
      const shopDisconnected = searchParams.get('shop-disconnected');
      const connectionStatus = searchParams.get('connection');
      const connectionFailedMessage = searchParams.get('message');
      if (shopDisconnected) {
        const shopName = shopDisconnected;
        toast.success(`${shopName} shop successfully disconnected.`, {
          toastId: 'shop-disconnected',
          position: 'bottom-right',
          autoClose: 5000,
        });
        setSearchParams({});
      }

      if (connectionStatus) {
        if (connectionStatus === 'successfull') {
          if (activeIntegrations.length > 0) {
            const details = await dispatch(
              shopsIntegrationsApi.endpoints.getActiveIntegrationDetails.initiate(
                {
                  id: activeIntegrations[0].shop_integration_platform_id,
                }
              )
            ).unwrap();

            toast.success(`Shop successfully connected.`, {
              toastId: 'shop-connected',
              position: 'bottom-right',
              autoClose: 5000,
            });
            captureEvent('Integration Connection Successful', {
              type: 'Etsy',
              shop_name: details.data.shop_name,
              shop_url: details.data.shop_url,
              shop_created_at: details.data.shop_created_at,
              shop_integration_id: details.data.shop_integration_id,
            });
            setSearchParams({});
          }
        }
        if (connectionStatus === 'failed') {
          if (connectionFailedMessage === 'etsy-shop-not-found') {
            setEtsyAlert(true);
          } else {
            toast.error(`Integration Connection Failed.`, {
              toastId: 'shop-connection-failed',
              position: 'bottom-right',
              autoClose: 5000,
            });
          }
          let errorMessage =
            'Something went wrong in getting tokens from Etsy.';

          switch (connectionFailedMessage) {
            case 'etsy-state-not-exist':
              errorMessage = 'State for Shop Integration do not exist.';
              break;
            case 'etsy-error-getting-tokens':
              errorMessage =
                'Something went wrong in getting tokens from Etsy.';
              break;
            case 'etsy-shop-not-found':
              errorMessage =
                "It looks like you don't have an Etsy shop associated with your account. Please create a shop on Etsy first, then click the 'Connect' button to connect your store and start syncing mockups with your products.";
              break;
            default:
              errorMessage =
                'Something went wrong in getting tokens from Etsy.';
              break;
          }
          captureEvent('Integration Connection Failed', {
            type: 'Etsy',
            error_message: errorMessage,
          });

          setSearchParams({});
        }
      }
    }

    handleConnectionStatus();
  }, [activeIntegrations]);

  return (
    <Flex className="view-wrapper-shops" direction={'column'} gap={'4'}>
      <Helmet>
        <title>Dynamic Mockups | Shops</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex
        className="header"
        px={'5'}
        py={'3'}
        align={'center'}
        justify={'between'}
        gap={'4'}
      >
        <Text weight={'bold'} size={'5'}>
          Shops
        </Text>

        <Button
          color="gray"
          variant="outline"
          onClick={() =>
            window.open('https://dynamicmockups.featurebase.app/', '_blank')
          }
        >
          <BulbIcon className="icon black" />
          <Text size="1" weight="medium">
            Request an Integration
          </Text>
        </Button>
      </Flex>

      <ScrollArea
        className="scroll-area-wrapper"
        type="hover"
        scrollbars="vertical"
        style={{ height: 'calc(100vh - 77px)' }}
      >
        {etsyShopConnect ? (
          <Flex
            className="view-wrapper"
            direction={'column'}
            gap={'3'}
            px={'5'}
          >
            <Flex direction={'column'} align={'start'} gap={'4'}>
              <Tabs.Root defaultValue="available" style={{ width: '100%' }}>
                <Tabs.List>
                  <Tabs.Trigger value="available">Available</Tabs.Trigger>
                </Tabs.List>

                <Box pt="3">
                  <Tabs.Content value="available">
                    <Flex
                      direction={'row'}
                      align={'center'}
                      justify={'start'}
                      width={'100%'}
                      gap={'2'}
                      wrap={'wrap'}
                      className="items-card-wrapper"
                    >
                      {shopIntegrationPlatformsQuery.isLoading ||
                      isFilteringAvailablePlatforms ? (
                        <Skeleton>
                          <Card className="item-card" size={'5'}>
                            <Flex direction={'column'}>
                              <Flex align={'center'} justify={'between'}>
                                {'placeholder'}
                                <Button
                                  color="gray"
                                  variant="outline"
                                  className="connect"
                                  loading={isConnectingShop}
                                >
                                  <PlusIcon className="icon black" />
                                  <Text size="1" weight="medium">
                                    Connect
                                  </Text>
                                </Button>
                              </Flex>
                              <Text
                                className="title"
                                size={'2'}
                                weight={'bold'}
                                mb={'2'}
                                mt={'4'}
                              >
                                {'placeholder'}
                              </Text>
                              <Text
                                size={'1'}
                                weight={'regular'}
                                className="description"
                              >
                                Connect your {'placeholder'} store and
                                automatically sync products and mockups avoiding
                                the need for repetitive and manual updates.
                              </Text>
                            </Flex>
                          </Card>
                        </Skeleton>
                      ) : (
                        availableShopIntegrationPlatforms?.map((shop) => (
                          <Card
                            key={shop?.name}
                            className="item-card"
                            size={'5'}
                          >
                            <Flex direction={'column'}>
                              <Flex align={'center'} justify={'between'}>
                                {shop?.icon}
                                {activeIntegrations.some(
                                  (activeIntegration) =>
                                    activeIntegration.name === shop.name
                                ) ? (
                                  <Flex align={'center'}>
                                    <Badge
                                      color="green"
                                      className="connected"
                                      mr={'3'}
                                    >
                                      CONNECTED
                                    </Badge>

                                    <Button
                                      color="gray"
                                      variant="ghost"
                                      size={'1'}
                                    >
                                      <GearIcon
                                        onClick={() =>
                                          navigate(`/shops/${shop?.id}`)
                                        }
                                        className="icon black"
                                        width={'13px'}
                                        height={'13px'}
                                      />
                                    </Button>
                                  </Flex>
                                ) : (
                                  <Button
                                    onClick={() => connectShop(shop?.id)}
                                    color="gray"
                                    variant="outline"
                                    className="connect"
                                    loading={isConnectingShop}
                                  >
                                    <PlusIcon className="icon black" />
                                    <Text size="1" weight="medium">
                                      Connect
                                    </Text>
                                  </Button>
                                )}
                              </Flex>
                              <Text
                                className="title"
                                size={'2'}
                                weight={'bold'}
                                mb={'2'}
                                mt={'4'}
                              >
                                {shop?.name}
                              </Text>
                              <Text
                                size={'1'}
                                weight={'regular'}
                                className="description"
                              >
                                Connect your {shop?.name} store and
                                automatically sync products and mockups avoiding
                                the need for repetitive and manual updates.
                              </Text>
                            </Flex>
                          </Card>
                        ))
                      )}
                    </Flex>
                  </Tabs.Content>
                </Box>
              </Tabs.Root>
              <Tabs.Root defaultValue="soon-to-come">
                <Tabs.List>
                  <Tabs.Trigger value="soon-to-come">Soon to come</Tabs.Trigger>
                </Tabs.List>

                <Box pt="3">
                  <Tabs.Content value="soon-to-come">
                    <Flex
                      direction={'row'}
                      align={'center'}
                      justify={'start'}
                      width={'100%'}
                      gap={'2'}
                      wrap={'wrap'}
                      className="items-card-wrapper"
                    >
                      {soonToComeShops?.map((shop) => (
                        <Card key={shop?.name} className="item-card" size={'5'}>
                          <Flex direction={'column'}>
                            <Flex align={'center'} justify={'between'}>
                              {shop?.icon}
                              {(shop.posthogProp ===
                                'early_adopter_shopify_integration' &&
                                !integrationShopifySubscribed) ||
                              (shop.posthogProp ===
                                'early_adopter_etsy_integration' &&
                                !integrationEtsySubscribed) ||
                              (shop.posthogProp ===
                                'early_adopter_woocommerce_integration' &&
                                !integrationWooCommerceSubscribed) ||
                              (shop.posthogProp ===
                                'early_adopter_wix_integration' &&
                                !integrationWixSubscribed) ||
                              (shop.posthogProp ===
                                'early_adopter_amazonmerch_integration' &&
                                !integrationAmazonMmerchSubscribed) ||
                              (shop.posthogProp ===
                                'early_adopter_ebay_integration' &&
                                !integrationEbaySubscribed) ||
                              (shop.posthogProp ===
                                'early_adopter_bigcommerce_integration' &&
                                !integrationBigCommerceSubscribed) ? (
                                <Button
                                  event-tracker-id={`${shop.posthogProp}`}
                                  variant="solid"
                                  color="blue"
                                  className="action"
                                  onClick={() =>
                                    captureUserProp(shop.posthogProp)
                                  }
                                >
                                  ⭐️
                                  <Text size="1" weight="medium">
                                    Get Early Access
                                  </Text>
                                </Button>
                              ) : (
                                <Badge
                                  color="blue"
                                  style={{ fontWeight: 'bold' }}
                                >
                                  Early Access Requested
                                </Badge>
                              )}
                            </Flex>
                            <Text
                              className="title"
                              size={'2'}
                              weight={'bold'}
                              mb={'2'}
                              mt={'4'}
                            >
                              {shop?.name}
                            </Text>
                            <Text
                              size={'1'}
                              weight={'regular'}
                              className="description"
                            >
                              Connect your {shop?.name} store and automatically
                              sync products and mockups avoiding the need for
                              repetitive and manual updates.
                            </Text>
                          </Flex>
                        </Card>
                      ))}
                    </Flex>
                  </Tabs.Content>
                </Box>
              </Tabs.Root>
            </Flex>
          </Flex>
        ) : (
          <ScrollArea
            className="scroll-area-wrapper"
            type="hover"
            scrollbars="vertical"
            style={{ height: 'calc(100vh - 77px)' }}
          >
            <Flex
              className="view-wrapper"
              direction={'column'}
              gap={'3'}
              px={'5'}
            >
              <Flex direction={'column'} align={'start'} gap={'4'}>
                <Flex
                  direction={'row'}
                  align={'center'}
                  justify={'start'}
                  width={'100%'}
                  gap={'2'}
                  wrap={'wrap'}
                  className="items-card-wrapper"
                >
                  {integrationPlatforms.map((shop) => (
                    <Card key={shop?.name} className="item-card" size={'5'}>
                      <Flex direction={'column'}>
                        <Flex align={'center'} justify={'between'}>
                          {shop?.icon}
                          {(shop.posthogProp ===
                            'early_adopter_shopify_integration' &&
                            !integrationShopifySubscribed) ||
                          (shop.posthogProp ===
                            'early_adopter_etsy_integration' &&
                            !integrationEtsySubscribed) ||
                          (shop.posthogProp ===
                            'early_adopter_woocommerce_integration' &&
                            !integrationWooCommerceSubscribed) ||
                          (shop.posthogProp ===
                            'early_adopter_wix_integration' &&
                            !integrationWixSubscribed) ||
                          (shop.posthogProp ===
                            'early_adopter_amazonmerch_integration' &&
                            !integrationAmazonMmerchSubscribed) ||
                          (shop.posthogProp ===
                            'early_adopter_ebay_integration' &&
                            !integrationEbaySubscribed) ||
                          (shop.posthogProp ===
                            'early_adopter_bigcommerce_integration' &&
                            !integrationBigCommerceSubscribed) ? (
                            <Button
                              event-tracker-id={`${shop.posthogProp}`}
                              variant="solid"
                              color="blue"
                              className="action"
                              onClick={() => captureUserProp(shop.posthogProp)}
                            >
                              ⭐️
                              <Text size="1" weight="medium">
                                Get Early Access
                              </Text>
                            </Button>
                          ) : (
                            <Badge color="blue" style={{ fontWeight: 'bold' }}>
                              Early Access Requested
                            </Badge>
                          )}
                        </Flex>
                        <Text
                          className="title"
                          size={'2'}
                          weight={'bold'}
                          mb={'2'}
                          mt={'4'}
                        >
                          {shop?.name}
                        </Text>
                        <Text
                          size={'1'}
                          weight={'regular'}
                          className="description"
                        >
                          Connect your {shop?.name} store and automatically sync
                          products and mockups avoiding the need for repetitive
                          and manual updates.
                        </Text>
                      </Flex>
                    </Card>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </ScrollArea>
        )}
      </ScrollArea>
      <AlertDialog.Root open={etsyAlert}>
        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title>Etsy connection failed</AlertDialog.Title>
          <AlertDialog.Description size="2">
            {noShopErrorMessage}
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Action>
              <Button variant="solid" onClick={() => setEtsyAlert(false)}>
                Got it
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </Flex>
  );
};
