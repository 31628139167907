import {
  Dialog,
  Button,
  Flex,
  TextField,
  Select,
  Text,
  Switch,
} from '@radix-ui/themes';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@/hooks';
import { psdManagementApi } from '@/services/psdManagement';
import {
  PrivatePhotoshopFile,
  PrivatePsd,
  PsdAvailability,
  PsdCategory,
  SubscriptionType,
} from '@/services/types';
import {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IFormInput {
  mockupName: string;
  mockupCategory: string | number;
  psdAvailability: number;
  subscriptionType: number;
}

interface IEditPhotoshopFile {
  psdAvailabilities: PsdAvailability[] | undefined;
  psdCategories: PsdCategory[] | undefined;
  privatePsd: PrivatePhotoshopFile;
  subscriptionTypes: SubscriptionType[] | undefined;
}

export const EditPhotoshopFile = forwardRef(
  (
    {
      psdAvailabilities,
      psdCategories,
      privatePsd,
      subscriptionTypes,
    }: IEditPhotoshopFile,
    ref
  ) => {
    const dispatch = useAppDispatch();
    const captureEvent = useCaptureEvent();
    const [open, setOpen] = useState<boolean>(false);
    const [updateMockupIsLoading, setUpdateMockupIsLoading] = useState(false);
    const [mockupCategory, setMockupCategory] = useState<number | null>(
      privatePsd.psd_category_id || 2
    );
    const [mockupName, setMockupName] = useState<string>(privatePsd.name);

    const [psdAvailability, setPsdAvailability] = useState<
      PsdAvailability | undefined
    >();
    const [subscriptionType, setSubscriptionType] = useState<
      SubscriptionType | undefined
    >();
    const formRef = useRef(null);
    const canPublishPrivatePsd = useFeatureFlagEnabled('publish-private-psd'); // just admins

    useEffect(() => {
      setPsdAvailability(
        psdAvailabilities?.find(
          (pa) => pa.id === privatePsd.psd_availability_id
        )
      );
    }, [psdAvailabilities]);

    useEffect(() => {
      if (subscriptionTypes?.length) {
        setSubscriptionType(
          subscriptionTypes?.find(
            (st) => st.id === privatePsd.subscription_type_id
          )
        );
      }
    }, [subscriptionTypes]);

    useImperativeHandle(ref, () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm<IFormInput>();
    const onSubmit: SubmitHandler<IFormInput> = (data) =>
      updateMockupAction(data);

    const updateMockupAction = async (formInput: IFormInput) => {
      try {
        setUpdateMockupIsLoading(true);
        const data: PrivatePsd = {
          psd_id: privatePsd.psd_id,
          name: mockupName,
          psd_category_id: mockupCategory as number,
          psd_availability_id: canPublishPrivatePsd
            ? (psdAvailability?.id as number)
            : psdAvailabilities?.find((pa) => pa.value === 2)?.id,
          subscription_type_id: canPublishPrivatePsd
            ? (subscriptionType?.id as number)
            : subscriptionTypes?.find((st) => st.name === 'Free')?.id,
        };
        await dispatch(
          psdManagementApi.endpoints.updatePrivatePsd.initiate(data)
        ).unwrap();

        dispatch(
          psdManagementApi.endpoints.getPrivatePsds.initiate(null!, {
            forceRefetch: true,
          })
        );
        captureEvent('PSD Mockup details saved');

        toast.success('Mockup successfully updated.', {
          toastId: 'update_mockup_success',
          position: 'bottom-right',
          autoClose: 5000,
        });

        // navigate(`/mockup/${successUploadPsdResponse?.response.uuid}`);
      } catch (error: any) {
        toast.error(error.data.message, {
          toastId: 'update_mockup_error',
          position: 'bottom-right',
          autoClose: 7000,
        });
      } finally {
        setUpdateMockupIsLoading(false);
      }
    };

    const sortCategories = (categories: PsdCategory[]) => {
      const data = [...categories];
      if (!data.length) {
        return [];
      }
      data.sort((a, b) => {
        if (a.name === 'Other') return 1;
        if (b.name === 'Other') return -1;
        return a.name.localeCompare(b.name);
      });
      return data;
    };

    return (
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Content aria-describedby={undefined} style={{ maxWidth: 450 }}>
          <Dialog.Title>
            <Flex direction={'row'} align={'center'} justify={'between'}>
              <Text>Edit Photoshop file</Text>
            </Flex>
          </Dialog.Title>
          <Flex direction={'column'}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              ref={formRef}
            >
              <label>
                <TextField.Slot>
                  <Text weight={'medium'} size={'2'} mb={'1'}>
                    Mockup Name
                  </Text>
                </TextField.Slot>
                <TextField.Root
                  placeholder="Mockup Name"
                  value={mockupName}
                  onChange={(e) => setMockupName(e.currentTarget.value)}
                />
              </label>
              {errors.mockupName && (
                <Text role="alert" size={'1'} color="red">
                  {errors.mockupName.message}
                </Text>
              )}
              <label>
                <TextField.Slot>
                  <Text weight={'medium'} size={'2'} mt={'4'} mb={'1'}>
                    Category
                  </Text>
                </TextField.Slot>
                <Select.Root
                  value={mockupCategory?.toString()}
                  onValueChange={(e) => setMockupCategory(parseInt(e))}
                >
                  <Select.Trigger style={{ width: '100%' }} />
                  <Select.Content position="popper" style={{ width: '100%' }}>
                    {sortCategories(psdCategories || [])?.map((category) => (
                      <Select.Item
                        key={category.id}
                        value={category.id.toString()}
                      >
                        {category.name}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
              </label>
              {errors.mockupCategory && (
                <Text role="alert" size={'1'} color="red">
                  {errors.mockupCategory.message}
                </Text>
              )}
              {canPublishPrivatePsd && (
                <>
                  <label>
                    <Flex direction={'row'} align={'end'} justify={'between'}>
                      <TextField.Slot>
                        <Text weight={'medium'} size={'2'} mt={'4'}>
                          Public mockup
                        </Text>
                      </TextField.Slot>
                      <Flex gap="2" align={'center'}>
                        <Switch
                          size="2"
                          onCheckedChange={(val) => {
                            const paValue: number = val ? 0 : 2;
                            setPsdAvailability(
                              psdAvailabilities?.find(
                                (pa) => pa.value === paValue
                              )
                            );
                          }}
                          defaultChecked={psdAvailability?.value === 0}
                        />
                      </Flex>
                    </Flex>
                  </label>
                  {errors.psdAvailability && (
                    <Text role="alert" size={'1'} color="red">
                      {errors.psdAvailability.message}
                    </Text>
                  )}
                </>
              )}
              {canPublishPrivatePsd && (
                <>
                  <label>
                    <Flex direction={'row'} align={'end'} justify={'between'}>
                      <TextField.Slot>
                        <Text weight={'medium'} size={'2'} mt={'4'}>
                          Pro mockup
                        </Text>
                      </TextField.Slot>
                      <Flex gap="2" align={'center'}>
                        <Switch
                          size="2"
                          onCheckedChange={(val) => {
                            const stName: string = val ? 'Pro' : 'Free';
                            setSubscriptionType(
                              subscriptionTypes?.find(
                                (st) => st.name === stName
                              )
                            );
                          }}
                          defaultChecked={subscriptionType?.name === 'Pro'}
                        />
                      </Flex>
                    </Flex>
                  </label>
                  {errors.subscriptionType && (
                    <Text role="alert" size={'1'} color="red">
                      {errors.subscriptionType.message}
                    </Text>
                  )}
                </>
              )}
              <Flex gap="3" mt="4" justify="end">
                <Dialog.Close>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </Dialog.Close>
                <Dialog.Close>
                  <Button type="submit" loading={updateMockupIsLoading}>
                    Save Changes
                  </Button>
                </Dialog.Close>
              </Flex>
            </form>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    );
  }
);
