import './view-wrapper.scss';
import {
  Badge,
  Button,
  DropdownMenu,
  Flex,
  Link,
  Text,
  Tooltip,
} from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { PrivatePhotoshopFile } from '@/services/types';
import { psdEngineApi } from '@/services/psdEngine';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { psdManagementApi } from '@/services/psdManagement';
import posthog from 'posthog-js';
import { useRef } from 'react';
import { ReactComponent as PhotoshopIcon } from '@/assets/icons/photoshop.svg';
import { EditPhotoshopFile } from './EditPhotoshopFile';
import { DeletePhotoshopFile } from './DeletePhotoshopFile';
import { useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';

interface IPhotoshopFilesSingleTemplate {
  privatePsd: PrivatePhotoshopFile;
}

export const PhotoshopFilesSingleTemplate = ({
  privatePsd,
}: IPhotoshopFilesSingleTemplate) => {
  const navigate = useNavigate();
  const captureEvent = useCaptureEvent();
  const editPhotoshopFileDialog = useRef();
  const deletePhotoshopFileDialog = useRef();
  const { psdCategories } = useAppSelector(
    (state) => state.psdManagementReducer
  );

  const [loadMockup, { isLoading }] =
    psdEngineApi.useLoadPublicPsdMockupMutation();
  const psdCategoriesData = psdManagementApi.useGetPsdCategoriesQuery();
  const psdAvailabilities = psdManagementApi.useGetPsdAvailabilitiesQuery();
  const subscriptionTypes = psdManagementApi.useGetSubscriptionTypesQuery();

  const loadPublicMockup = async () => {
    navigate(`/mockup/${privatePsd.psd_id}`, {
      state: { from: location.pathname },
    });
  };

  return (
    <Flex
      direction={'column'}
      gap={'3'}
      className={'photoshop-files-single-template'}
    >
      <EditPhotoshopFile
        psdAvailabilities={psdAvailabilities.data?.data}
        subscriptionTypes={subscriptionTypes.data?.data}
        psdCategories={psdCategoriesData.data?.data}
        privatePsd={privatePsd}
        ref={editPhotoshopFileDialog}
      />
      <DeletePhotoshopFile
        privatePsd={privatePsd}
        ref={deletePhotoshopFileDialog}
      />
      <Flex align={'center'} justify={'between'}>
        <Flex align={'center'} gap={'1'}>
          <PhotoshopIcon />
          <Tooltip content={privatePsd.name}>
            <Text className="title" size={'1'} weight={'regular'}>
              {privatePsd.name}
            </Text>
          </Tooltip>
        </Flex>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button
              className="ddl-options"
              variant="ghost"
              size={'1'}
              color="gray"
            >
              <DotsVerticalIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <Link
              href={`${privatePsd.psd_url}`}
              download={`${privatePsd.name}`}
              target="_blank"
              className="download-action"
            >
              <DropdownMenu.Item
                onClick={() => captureEvent('PSD Mockup downloaded')}
              >
                Download
              </DropdownMenu.Item>
            </Link>
            <DropdownMenu.Item
              onClick={() => {
                (editPhotoshopFileDialog?.current as any)?.openDialog();
              }}
            >
              Edit
            </DropdownMenu.Item>
            <DropdownMenu.Separator />
            <DropdownMenu.Item
              color="red"
              onClick={() => {
                (deletePhotoshopFileDialog?.current as any)?.openDialog();
              }}
            >
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Flex>
      <Flex className="single-template photoshop-files" align={'end'}>
        <LazyLoadImage
          className=""
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            objectPosition: 'center',
          }}
          effect="blur"
          src={
            privatePsd.thumbnail || 'https://placehold.co/300x300?text=MOCKUP'
          }
          delayTime={0}
          placeholderSrc={
            privatePsd.thumbnail || 'https://placehold.co/300x300?text=MOCKUP'
          }
          wrapperProps={{
            style: { transitionDelay: '0s' },
          }}
        />
        {psdAvailabilities.data?.data?.find(
          (pa) => pa.id === privatePsd.psd_availability_id
        )?.value === 0 && (
          <Badge color="green" size={'2'} className="public-badge">
            Public
          </Badge>
        )}
        {subscriptionTypes.data?.data?.find(
          (st) => st.id === privatePsd.subscription_type_id
        )?.name === 'Pro' && (
          <Badge color="amber" size={'2'} className="paid-badge">
            Pro
          </Badge>
        )}
        <Flex className="text-wrapper" align={'end'} p={'4'}>
          <Button
            style={{ width: '100%' }}
            onClick={() => loadPublicMockup()}
            loading={isLoading}
          >
            Open Mockup
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
