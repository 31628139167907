import { useAppDispatch, useAppSelector } from '@/hooks';
import { setActiveVariation } from '@/redux/slices/collection';
import { psdEngineApi } from '@/services/psdEngine';
import {
  Button,
  Dialog,
  Flex,
  TextField,
  Text,
  Box,
  Tabs,
  IconButton,
  Tooltip,
} from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { TrashIcon } from '@radix-ui/react-icons';

const defaultColors = [
  { id: '1', hex: '#FF5733' },
  { id: '2', hex: '#33FF57' },
  { id: '3', hex: '#3357FF' },
  { id: '4', hex: '#FF33A1' },
  { id: '5', hex: '#A1FF33' },
  { id: '6', hex: '#33A1FF' },
  { id: '7', hex: '#FF3381' },
  { id: '8', hex: '#81FF33' },
  { id: '9', hex: '#3381FF' },
  { id: '10', hex: '#FF5733' },
  { id: '11', hex: '#33FF57' },
  { id: '12', hex: '#5733FF' },
  { id: '13', hex: '#FF5733' },
  { id: '14', hex: '#FF33C4' },
  { id: '15', hex: '#C4FF33' },
  { id: '16', hex: '#33C4FF' },
  { id: '17', hex: '#FFC433' },
  { id: '18', hex: '#33FFC4' },
  { id: '19', hex: '#C433FF' },
  { id: '20', hex: '#FFC433' },
  { id: '21', hex: '#FF3366' },
  { id: '22', hex: '#66FF33' },
  { id: '23', hex: '#3366FF' },
  { id: '24', hex: '#FF9933' },
  { id: '25', hex: '#33FF99' },
];

interface Color {
  id: string;
  hex: string;
}

interface Preset {
  id: string;
  name: string;
  colors: Color[];
}

export const PresetColors = () => {
  const dispatch = useAppDispatch();
  const { activeSmartObject, mockup } = useAppSelector(
    (state) => state.collectionReducer
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingColors, setIsLoadingColors] = useState(false);
  const [presetTabs, setPresetTabs] = useState('1');
  const [presets, setPresets] = useState<Preset[]>(() => {
    const savedPresets = localStorage.getItem('colorPresets');
    return savedPresets
      ? JSON.parse(savedPresets)
      : [{ id: '1', name: 'T-shirt', colors: defaultColors }];
  });

  const [addColorMutation] = psdEngineApi.useAddColorCombinationMutation();
  const [createMockupVariations] =
    psdEngineApi.useCreateMockupVariationsMutation();

  useEffect(() => {
    localStorage.setItem('colorPresets', JSON.stringify(presets));
  }, [presets]);

  const loadColors = async () => {
    setIsLoadingColors(true);
    setIsOpen(false);
    for (const color of getActivePresetColors()) {
      const addedColor = await addColorMutation({
        smart_object_id: activeSmartObject?.id,
        hex_code: color.hex,
      }).unwrap();

      const createdVariations = await createMockupVariations({
        mockup_id: mockup?.id,
        smart_object_id: activeSmartObject.id,
        color_id: addedColor.data.id,
      }).unwrap();

      dispatch(
        setActiveVariation(
          createdVariations.data[createdVariations.data.length - 1]
        )
      );
    }
    setIsLoadingColors(false);
  };

  const getActivePresetColors = (): Color[] => {
    const activePreset = presets.find((preset) => preset.id === presetTabs);
    return activePreset ? activePreset.colors : [];
  };

  const getContrastColor = (hex: string) => {
    // Remove the '#' if it exists
    hex = hex.replace('#', '');

    // Validate hex format (should be either 3 or 6 characters long and valid hex)
    if (!/^([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(hex)) {
      return '#000000'; // Return black for invalid hex
    }

    // If the hex code is 3 characters long, convert it to 6 characters
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((char) => char + char)
        .join('');
    }

    // Convert hex to RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return black or white based on luminance
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  };

  const addPreset = () => {
    const newId = (presets.length + 1).toString();
    const newName = `Preset ${presets.length + 1}`;
    setPresets([
      ...presets,
      { id: newId, name: newName, colors: defaultColors },
    ]);
    setPresetTabs(newId);
  };

  const removePreset = (id: string) => {
    setPresets(presets.filter((preset) => preset.id !== id));
    setPresetTabs('1');
  };

  const renamePreset = (id: string, newName: string) => {
    setPresets(
      presets.map((preset) =>
        preset.id === id ? { ...preset, name: newName } : preset
      )
    );
  };

  const updateColor = (presetId: string, colorId: string, newHex: string) => {
    setPresets((prevPresets) =>
      prevPresets.map((preset) =>
        preset.id === presetId
          ? {
              ...preset,
              colors: preset.colors.map((color) =>
                color.id === colorId ? { ...color, hex: newHex } : color
              ),
            }
          : preset
      )
    );
  };

  const addColor = () => {
    setPresets((prevPresets) =>
      prevPresets.map((preset) =>
        preset.id === presetTabs
          ? {
              ...preset,
              colors: [
                ...preset.colors,
                {
                  id: (preset.colors.length + 1).toString(),
                  hex: '#ffffff',
                },
              ],
            }
          : preset
      )
    );
  };

  const removeColor = (presetId: string, colorId: string) => {
    setPresets((prevPresets) =>
      prevPresets.map((preset) =>
        preset.id === presetId
          ? {
              ...preset,
              colors: preset.colors.filter((color) => color.id !== colorId),
            }
          : preset
      )
    );
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={(e) => setIsOpen(e)}>
      <Dialog.Trigger>
        <Button
          size={'1'}
          style={{
            background: 'linear-gradient(135deg, #7b2ff7 0%, #f107a3 100%)',
          }}
          loading={isLoadingColors}
        >
          Presets
        </Button>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="710px" style={{ minHeight: '660px' }}>
        <Flex direction={'column'} gap="2" justify={'between'} height={'100%'}>
          <Flex direction={'column'} gap={'2'}>
            <Dialog.Title>Manage color presets</Dialog.Title>

            <Tabs.Root
              defaultValue="account"
              value={presetTabs}
              onValueChange={(value) => setPresetTabs(value)}
            >
              <Tabs.List>
                {presets.map((preset) => (
                  <Tabs.Trigger
                    key={`preset-tab-${preset.id}`}
                    value={preset.id}
                  >
                    {preset.name}
                  </Tabs.Trigger>
                ))}
                <Flex align={'center'}>
                  <Tooltip content="Add new preset">
                    <Button size={'1'} ml={'2'} onClick={() => addPreset()}>
                      <PlusIcon className="icon white" />
                    </Button>
                  </Tooltip>
                </Flex>
              </Tabs.List>

              <Box pt="3">
                {presets.map((preset) => (
                  <Tabs.Content key={`preset-${preset.id}`} value={preset.id}>
                    <Flex direction={'column'} gap={'6'}>
                      <Flex gap={'2'} justify={'between'} align={'center'}>
                        <label>
                          <TextField.Slot>
                            <Text weight={'medium'} size={'2'} mb={'1'}>
                              Preset Name
                            </Text>
                          </TextField.Slot>
                          <TextField.Root
                            placeholder="T-shirts"
                            value={preset.name}
                            onChange={(e) =>
                              renamePreset(preset.id, e.currentTarget.value)
                            }
                          />
                        </label>
                        {preset.id !== '1' && (
                          <Button
                            color="red"
                            style={{ maxWidth: '120px' }}
                            onClick={() => removePreset(preset.id)}
                          >
                            Delete preset
                          </Button>
                        )}
                      </Flex>
                      <Flex gap={'2'} wrap={'wrap'}>
                        {preset.colors.map((color) => (
                          <TextField.Root
                            key={`color-${color.id}`}
                            placeholder="#fff"
                            value={color.hex}
                            onChange={(e) =>
                              updateColor(
                                preset.id,
                                color.id,
                                e.currentTarget.value
                              )
                            }
                            style={{
                              background: color.hex,
                              color: getContrastColor(color.hex),
                            }}
                          >
                            <TextField.Slot></TextField.Slot>
                            <TextField.Slot pr="3">
                              <Tooltip content="Remove color">
                                <IconButton
                                  size="2"
                                  variant="ghost"
                                  style={{
                                    background: color.hex,
                                    color: getContrastColor(color.hex),
                                  }}
                                  onClick={() =>
                                    removeColor(preset.id, color.id)
                                  }
                                >
                                  <TrashIcon height="16" width="16" />
                                </IconButton>
                              </Tooltip>
                            </TextField.Slot>
                          </TextField.Root>
                        ))}
                        <Flex align={'center'}>
                          <Tooltip content="Add new color">
                            <Button
                              size={'1'}
                              ml={'2'}
                              onClick={() => addColor()}
                            >
                              <PlusIcon className="icon white" />
                            </Button>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Tabs.Content>
                ))}
              </Box>
            </Tabs.Root>
          </Flex>

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button onClick={() => loadColors()} loading={isLoadingColors}>
              Load colors
            </Button>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
