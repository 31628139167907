import {
  Dialog,
  Flex,
  Grid,
  Text,
  Switch,
  Card,
  Button,
  Select,
} from '@radix-ui/themes';
import './appPpaymentPopup.scss';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAppDispatch, useAppSelector } from '@/hooks';
import posthog from 'posthog-js';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/check-linear.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close-linear.svg';
import { ReactComponent as ProCrownIcon } from '@/assets/icons/pro-crown.svg';
import { ReactComponent as PremiumCrownIcon } from '@/assets/icons/premium-crown.svg';
import { useLocation, useSearchParams } from 'react-router-dom';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { paymentsApi } from '@/services/payments';
import { toast } from 'react-toastify';
import {
  MonthlyPlan,
  monthlyPlansData,
  yearlyPlansData,
  monthlyPlansDataDEV,
  yearlyPlansDataDEV,
} from '../api-dashboard/components/ApiPaymentPopup';
import useCaptureEvent from '@/hooks/useCaptureEvent';

export const AppPaymentPopup = forwardRef(({}, ref) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const captureEvent = useCaptureEvent();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const { currentUser } = useAppSelector((state) => state.userReducer);

  const [monthlyApiPlans, setMonthlyApiPlans] = useState(
    !!parseInt(IS_PRODUCTION) ? monthlyPlansData : monthlyPlansDataDEV
  );
  const [yearlyApiPlans, setYearlyApiPlans] = useState(
    !!parseInt(IS_PRODUCTION) ? yearlyPlansData : yearlyPlansDataDEV
  );

  const [isAnnual, setIsAnnual] = useState<boolean>(true);
  const [selectedPanLink, setSelectedPlanLink] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams({});

  useEffect(() => {
    async function getCheckoutSessionAction() {
      const checkoutSessionId = searchParams.get(
        'checkout_session_id'
      ) as string;

      const data = await dispatch(
        paymentsApi.endpoints.getCheckoutSession.initiate(
          {
            checkoutSessionId,
          },
          {
            forceRefetch: true,
          }
        )
      ).unwrap();
      if (data.data.payment_status === 'paid') {
        captureEvent('Subscription Checkout completed', {
          $set: {
            ['api_subscriber']:
              location.pathname.includes('dashboard-api') || undefined,
            ['app_subscriber']:
              location.pathname.includes('account') || undefined,
            ['subscription_status']: 'active',
            ['customer_id']: data.data.customer_id,
            ['last_checkout_session']: location.pathname.includes('account')
              ? data.data
              : undefined,
            ['api_last_checkout_session']: location.pathname.includes(
              'dashboard-api'
            )
              ? data.data
              : undefined,
          },
        });

        await new Promise((resolve) => setTimeout(resolve, 250));
        posthog.reloadFeatureFlags();

        toast.success('Payment successfull.', {
          toastId: 'successfull_payment',
          position: 'bottom-right',
          autoClose: 5000,
        });
        setSearchParams({});
      }
    }

    if (searchParams.get('checkout_session_id')) {
      getCheckoutSessionAction();
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (isAnnual) {
        setSelectedPlanLink(
          (!!parseInt(IS_PRODUCTION)
            ? 'https://buy.stripe.com/eVadUu4Jp8cydgIeV7'
            : 'https://buy.stripe.com/test_8wMcNCbBM5Nx8fudQW') +
            '?prefilled_email=' +
            currentUser?.email
        );
        let updatedYearlyPlans = yearlyApiPlans.map((plan, index) => ({
          ...plan,
          stripeLink: !plan.stripeLink.includes('prefilled_email')
            ? plan.stripeLink + '?prefilled_email=' + currentUser?.email
            : plan.stripeLink,
          selected: index === 0 ? true : false,
        }));
        setYearlyApiPlans(updatedYearlyPlans);
      } else {
        setSelectedPlanLink(
          (!!parseInt(IS_PRODUCTION)
            ? 'https://buy.stripe.com/9AQ8AaejZ78ub8A28k'
            : 'https://buy.stripe.com/test_5kA28YcFQa3N9jyfZ3') +
            '?prefilled_email=' +
            currentUser?.email
        );
        let updatedMonthlyPlans = monthlyApiPlans.map((plan, index) => ({
          ...plan,
          stripeLink: !plan.stripeLink.includes('prefilled_email')
            ? plan.stripeLink + '?prefilled_email=' + currentUser?.email
            : plan.stripeLink,
          selected: index === 0 ? true : false,
        }));
        setMonthlyApiPlans(updatedMonthlyPlans);
      }
    }
  }, [currentUser, isAnnual]);

  useEffect(() => {
    dispatch(setIsAppPaymentPopupOpen(open));
  }, [open]);

  const goToStripePlanCheckout = () => {
    captureEvent('Payment initiated');
    window.location.replace(selectedPanLink);
  };

  useImperativeHandle(ref, () => ({
    openDialog() {
      setOpen(true);
    },
    closeDialog() {
      setOpen(false);
    },
  }));

  const selectMonthlyPlan = (plan: MonthlyPlan) => {
    const updatedPlans = monthlyApiPlans.map((item) => ({
      ...item,
      selected: item.amount === plan.amount,
    }));
    captureEvent(
      `Plan ${plan.amount} Monthly Renders, $${plan.price} / month selected`
    );

    setMonthlyApiPlans(updatedPlans);
  };

  const selectYearlyPlan = (plan: MonthlyPlan) => {
    const updatedPlans = yearlyApiPlans.map((item) => ({
      ...item,
      selected: item.amount === plan.amount,
    }));
    captureEvent(
      `Plan ${plan.amount} Monthly Renders, $${plan.discountedPrice} / year selected`
    );

    setYearlyApiPlans(updatedPlans);
  };

  const getSelectedPlan = () => {
    if (isAnnual) {
      return yearlyApiPlans.find((plan) => plan.selected);
    } else {
      return monthlyApiPlans.find((plan) => plan.selected);
    }
  };

  const goToStripeApiPlanCheckout = () => {
    captureEvent('Payment initiated');
    window.location.replace(getSelectedPlan()?.stripeLink || '');
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content
        aria-describedby={undefined}
        className="app-payment-popup-wrapper"
        style={{
          maxWidth: 1140,
          maxHeight: 670,
        }}
        width={'100%'}
        onPointerDownOutside={() =>
          captureEvent('Upgrade your plan modal closed')
        }
      >
        <Flex direction={'column'} align={'center'} gap={'5'}>
          <Flex direction={'column'} align={'center'} gap={'3'}>
            <Dialog.Title mb={'0'}>
              <Text size={'5'} weight={'bold'}>
                Upgrade your plan
              </Text>
            </Dialog.Title>

            <Text size={'2'} className="subtitle">
              Upgrade to access all Mockups and premium features
            </Text>
          </Flex>
          <Flex gap={'2'}>
            <Text size={'2'}>Save 25% with a yearly plan</Text>
            <Switch
              defaultChecked={isAnnual}
              onCheckedChange={(checked) => setIsAnnual(checked)}
            />
          </Flex>
        </Flex>
        <Grid columns={isMobile ? '1fr' : '3fr 3fr 3fr'} rows={'1fr'} gap={'4'}>
          <Card className={`item-card`} size={'5'}>
            <Flex direction={'column'} height={'100%'}>
              <Flex direction={'column'} gap={'2'} px={'5'} py={'6'}>
                <Text className="title">Free</Text>
                <Text className="price">$0</Text>
                <Text className="description">Free forever</Text>
              </Flex>
              <Flex
                className="content"
                direction={'column'}
                justify={'between'}
                height={'100%'}
                p={'5'}
                gap={'5'}
              >
                <Flex
                  className="item-description"
                  direction={'column'}
                  gap={'2'}
                >
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Free & Premium Mockups
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      JPG, PNG, WEBP Formats
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Bulk Export Mockup Variants
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CloseIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Custom Photoshop Mockups
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CloseIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      E-commerce Integrations (Free in Beta)
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CloseIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Mockup Generator API
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={'2'} className="cta-button-wrapper back">
                  <Button
                    variant={'outline'}
                    color="gray"
                    onClick={() => {
                      captureEvent('Upgrade your plan modal closed');
                      setOpen(false);
                    }}
                  >
                    Stay Basic
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Card>
          <Card className={`item-card pro`} size={'5'}>
            <Flex direction={'column'} height={'100%'}>
              <Flex direction={'column'} gap={'2'} px={'5'} py={'6'}>
                <Flex align={'center'} gap={'2'}>
                  <ProCrownIcon width={'24px'} height={'24px'} />
                  <Text className="title">Pro</Text>
                </Flex>
                <Flex align={'end'} gap={'1'}>
                  <Text className="price">{isAnnual ? '$14' : '$19'}</Text>
                  <Text>/ month</Text>
                </Flex>
                <Text
                  className={`description transition ${
                    isAnnual ? 'visible' : ''
                  }`}
                >
                  Billed $169 annually
                </Text>
              </Flex>
              <Flex
                className="content"
                direction={'column'}
                justify={'between'}
                height={'100%'}
                p={'5'}
                gap={'5'}
              >
                <Flex
                  className="item-description"
                  direction={'column'}
                  gap={'2'}
                >
                  {/* <Flex
                    gap={'2'}
                    align={'center'}
                    className={`transition ${isAnnual ? 'visible' : ''}`}
                  >
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Save $59 annually
                    </Text>
                  </Flex> */}
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Free & Premium Mockups
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      JPG, PNG, WEBP Formats
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Bulk Export Variants
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Custom Photoshop Mockups
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      E-commerce Integrations
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CloseIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Mockup Generator API
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={'2'} className="cta-button-wrapper">
                  <Button
                    variant={'solid'}
                    color="blue"
                    onClick={() => goToStripePlanCheckout()}
                  >
                    Upgrade to Pro
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Card>
          <Card className={`item-card premium`} size={'5'}>
            <Flex direction={'column'} height={'100%'}>
              <Flex direction={'column'} gap={'2'} px={'5'} py={'6'}>
                <Flex align={'center'} gap={'2'}>
                  <PremiumCrownIcon width={'24px'} height={'24px'} />
                  <Text className="title">Premium</Text>
                </Flex>
                <Flex align={'end'} gap={'1'}>
                  <Text className="price">
                    {isAnnual
                      ? `$${
                          yearlyApiPlans.find((plan) => plan.selected)
                            ?.discountedMonthlyPrice
                        }`
                      : `$${
                          monthlyApiPlans.find((plan) => plan.selected)?.price
                        }`}
                  </Text>
                  <Text>/ month</Text>
                </Flex>
                <Text
                  className={`description transition ${
                    isAnnual ? 'visible' : ''
                  }`}
                >
                  {`Billed $${
                    yearlyApiPlans.find((plan) => plan.selected)
                      ?.discountedPrice
                  } annually`}
                </Text>
              </Flex>

              <Flex
                className="content"
                direction={'column'}
                justify={'between'}
                height={'100%'}
                p={'5'}
                gap={'5'}
              >
                <Select.Root
                  value={
                    isAnnual
                      ? yearlyApiPlans.find((plan) => plan.selected)?.stripeLink
                      : monthlyApiPlans.find((plan) => plan.selected)
                          ?.stripeLink
                  }
                  onValueChange={(e) => {
                    if (isAnnual) {
                      selectYearlyPlan(
                        yearlyApiPlans.find(
                          (plan) => plan.stripeLink === e
                        ) as MonthlyPlan
                      );
                    } else {
                      selectMonthlyPlan(
                        monthlyApiPlans.find(
                          (plan) => plan.stripeLink === e
                        ) as MonthlyPlan
                      );
                    }
                  }}
                >
                  <Select.Trigger />
                  <Select.Content position="popper">
                    {(isAnnual ? yearlyApiPlans : monthlyApiPlans).map(
                      (plan) => (
                        <Select.Item
                          value={plan.stripeLink}
                          key={plan.stripeLink}
                        >
                          {plan.amount} monthly API renders
                        </Select.Item>
                      )
                    )}
                  </Select.Content>
                </Select.Root>

                <Flex
                  className="item-description"
                  direction={'column'}
                  gap={'2'}
                >
                  {/* <Flex
                    gap={'2'}
                    align={'center'}
                    className={`transition ${isAnnual ? 'visible' : ''}`}
                  >
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      {`Save $${
                        yearlyApiPlans.find((plan) => plan.selected)?.saved
                      } annually`}
                    </Text>
                  </Flex> */}
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Everything from Pro plan
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Mockup Generator API
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      Embed Mockup Editor (SOON)
                    </Text>
                  </Flex>
                  <Flex gap={'2'} align={'center'}>
                    <CheckmarkIcon
                      className="icon"
                      width={'20px'}
                      height={'20px'}
                    />
                    <Text size={'2'} weight={'medium'}>
                      24/7 Priority Support
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap={'2'} className="cta-button-wrapper">
                  <Button
                    variant={'solid'}
                    color="purple"
                    onClick={() => goToStripeApiPlanCheckout()}
                  >
                    Upgrade to Premium
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Card>
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
});
