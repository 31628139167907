import './view-wrapper.scss';
import { Button, Flex, Grid, ScrollArea, Text } from '@radix-ui/themes';
import { SingleTemplate } from './SingleTemplate';
import { workspaceApi } from '@/services/workspace';
import Skeleton from 'react-loading-skeleton';
import { useAppSelector } from '@/hooks';
import { ReactComponent as TreeSquaresIcon } from '@/assets/icons/three-squares-linear.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { CollectionsWidget } from './CollectionsWidget';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveMockupCollectionId } from '@/redux/slices/mockupCollections';

export const ViewWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { myProjects } = useAppSelector((state) => state.workspaceReducer);
  const { activeMockupCollectionId, mockupCollections } = useAppSelector(
    (state) => state.mockupCollectionsReducer
  );

  useEffect(() => {
    dispatch(setActiveMockupCollectionId(0));
  }, []);

  const geyMyProjects = workspaceApi.useGetMyProjectsQuery(
    { collectionId: activeMockupCollectionId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (location.pathname.includes('/my-templates')) {
      if (activeMockupCollectionId !== 0) {
        navigate(
          `/my-templates/${
            mockupCollections.find(
              (collection) => collection.id === activeMockupCollectionId
            )?.uuid
          }`
        );
      } else {
        navigate(`/my-templates`);
      }
    }
  }, [activeMockupCollectionId]);

  return (
    <ScrollArea
      className="scroll-area-wrapper"
      type="hover"
      scrollbars="vertical"
      style={{ height: 'calc(100vh - 69px)' }}
    >
      <Flex px={'5'} gap={'5'} className="widget-view-wrapper">
        <CollectionsWidget />
        <Grid
          className="view-wrapper"
          gap={'2'}
          style={{
            display:
              !geyMyProjects.isLoading && myProjects.length === 0
                ? 'flex'
                : 'grid',
            height:
              !geyMyProjects.isLoading && myProjects.length === 0
                ? 'calc(100vh - 69px)'
                : '100%',
          }}
        >
          {geyMyProjects.isLoading || geyMyProjects.isFetching
            ? [...Array(12)].map((item, index) => (
                <Skeleton key={`${index}-item`} className="single-template" />
              ))
            : myProjects
                ?.slice(0)
                .reverse()
                .map((project) => (
                  <SingleTemplate key={project.id} project={project} />
                ))}

          {!geyMyProjects.isLoading && myProjects.length === 0 && (
            <Flex
              direction={'column'}
              align={'center'}
              justify={'center'}
              gap={'4'}
              className="empty-page-state"
            >
              <Flex align={'center'} justify={'center'} className="cercle-wrap">
                <TreeSquaresIcon className="svg-icon" />
              </Flex>
              <Text size="2" weight="regular" align={'center'}>
                Organize Mockups in Collections by adding them from the All
                Mockups folder.
              </Text>

              <Button
                variant="solid"
                event-tracker-id="upload-psd-button"
                onClick={() => dispatch(setActiveMockupCollectionId(0))}
              >
                <Text size="1" weight="medium">
                  All Mockups
                </Text>
              </Button>
            </Flex>
          )}
        </Grid>
      </Flex>
    </ScrollArea>
  );
};
