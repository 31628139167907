import { useEffect, useRef, useState } from 'react';
import { Box, Text, Flex, Button, ScrollArea } from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  setActiveColorCombination,
  setActiveVariation,
  setColorCombinationsContainerHeight,
} from '@/redux/slices/collection';
import '../editor.scss';
import { ReactComponent as PenBold } from '@/assets/icons/pen-bold.svg';
import { setActiveView, setActiveAction } from '@/redux/slices/editor';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { psdEngineApi } from '@/services/psdEngine';
import { useParams } from 'react-router-dom';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { PresetColors } from '@/features/components/programmatic-seo-tools/PresetColors';

interface IColorCombinations {}

export const ColorCombinations = ({}: IColorCombinations) => {
  const dispatch = useAppDispatch();
  const {
    colorCombinations,
    activeColorCombination,
    activeDesignAsset,
    activeSmartObject,
    activeVariation,
    mockupVariations,
    isCreatingVariations,
  } = useAppSelector((state) => state.collectionReducer);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  useEffect(() => {
    const activeVariationColorId = activeVariation?.variation_elements.find(
      (ve) => ve.smart_object_id === activeSmartObject?.id
    )?.color_id;

    dispatch(
      setActiveColorCombination(
        colorCombinations[activeSmartObject?.id]?.find(
          (color) => color.id === activeVariationColorId
        )
      )
    );
  }, [activeVariation, activeSmartObject]);

  const changeActiveColorCombination = (id: number) => {
    if (isCreatingVariations) {
      return;
    }
    const variation = mockupVariations.find((mv) =>
      mv.variation_elements.find(
        (ve) =>
          ve?.color_id === id &&
          (activeDesignAsset?.id
            ? ve?.asset_id === activeDesignAsset?.id
            : true)
      )
    );
    if (variation) {
      dispatch(setActiveVariation(variation));
    }
  };

  const colorVariantAction = async (action: 'add' | 'edit', ccId?: number) => {
    if (isCreatingVariations) {
      return;
    }
    changeActiveColorCombination(ccId!);
    await new Promise((resolve) => setTimeout(resolve, 50));

    dispatch(setActiveView('update-color'));
    dispatch(setActiveAction(action));
  };

  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      dispatch(
        setColorCombinationsContainerHeight((ref.current as any)?.clientHeight)
      );
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const programmaticTools = useFeatureFlagEnabled('programmatic-tools');

  return (
    <Flex
      className="color-combinations"
      direction={'column'}
      align={'start'}
      px={'4'}
      ref={ref}
    >
      {!isMobile && (
        <Flex
          className="info"
          direction={'row'}
          align={'center'}
          justify={'between'}
          gap={'2'}
        >
          <Flex align={'center'} justify={'between'} width={'100%'}>
            <Text weight={'medium'} size={'2'}>
              Color Combinations
            </Text>
            {programmaticTools && <PresetColors />}
          </Flex>
          {/* {colorCombinations?.[activeSmartObject?.id]?.length > 0 && (
            <Button
              id="open-add-new-color"
              variant="outline"
              color="gray"
              size={'1'}
              style={{ width: '32px', height: '32px' }}
              onClick={() => colorVariantAction('add')}
            >
              <PlusIcon width={'24px'} height={'24px'} className="icon" />
            </Button>
          )} */}
        </Flex>
      )}
      <ScrollArea
        type={isMobile ? 'always' : 'hover'}
        scrollbars={isMobile ? 'horizontal' : 'vertical'}
        className="colors-scroll-area"
      >
        <Flex
          className="color-items"
          wrap={'wrap'}
          pt={'4'}
          style={{
            width: isMobile
              ? colorCombinations?.[activeSmartObject?.id]?.length * 85 +
                80 +
                'px'
              : undefined,
          }}
        >
          {isMobile &&
            colorCombinations?.[activeSmartObject?.id]?.length !== 0 && (
              <Button
                className="new"
                variant="surface"
                color="gray"
                onClick={() => colorVariantAction('add')}
                id="open-add-new-color"
              >
                <PlusIcon className="icon" width={'25px'} height={'25px'} />
              </Button>
            )}

          {colorCombinations?.[activeSmartObject?.id]?.length === 0 &&
            [...Array(isMobile ? 3 : 6)].map((_, index) => (
              <Flex
                key={index}
                className={`placeholder color-item`}
                align={'center'}
                justify={'center'}
                id="open-add-new-color"
              >
                <Flex
                  className="color"
                  align={'center'}
                  justify={'center'}
                  onClick={() => colorVariantAction('add')}
                >
                  <PlusIcon width={'24px'} height={'24px'} className="icon" />
                </Flex>
              </Flex>
            ))}
          {colorCombinations[activeSmartObject.id]?.map((cc) => (
            <Flex
              key={cc.id}
              className={`color-item ${
                cc.id === activeColorCombination?.id && 'active'
              }`}
              align={'center'}
              justify={'center'}
              id="color-item"
              style={{ backgroundColor: cc.hex_code }}
            >
              <Box
                className="color"
                style={{ backgroundColor: cc.hex_code }}
                onClick={() => changeActiveColorCombination(cc.id)}
              ></Box>
              <Flex className="edit" align={'center'} justify={'center'}>
                <PenBold
                  width={'12px'}
                  height={'12px'}
                  onClick={() => colorVariantAction('edit', cc.id)}
                />
              </Flex>
            </Flex>
          ))}
          {!isMobile &&
            colorCombinations?.[activeSmartObject?.id]?.length > 0 && (
              <Flex
                className={`placeholder color-item`}
                align={'center'}
                justify={'center'}
                id="open-add-new-color"
              >
                <Flex
                  className="color"
                  align={'center'}
                  justify={'center'}
                  onClick={() => colorVariantAction('add')}
                >
                  <PlusIcon width={'24px'} height={'24px'} className="icon" />
                </Flex>
              </Flex>
            )}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};
