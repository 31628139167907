import { Dialog, Button, Flex, TextField, Text } from '@radix-ui/themes';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@/hooks';
import { Project } from '@/services/types';
import {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { mockupCollectionsApi } from '@/services/mockupCollections';

interface IFormInput {
  collectionName: string;
}

interface ICreateCollection {}

export const CreateCollection = forwardRef(({}: ICreateCollection, ref) => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const [open, setOpen] = useState<boolean>(false);
  const [createCollectionIsLoading, setCreateCollectionIsLoading] =
    useState(false);
  const [collectionName, setCollectionName] = useState<string>('');

  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setCollectionName('');
      reset();
      setOpen(true);
    },
    closeDialog() {
      setOpen(false);
    },
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) =>
    createCollectionAction(data);

  const createCollectionAction = async (formInput: IFormInput) => {
    try {
      setCreateCollectionIsLoading(true);
      const data = {
        name: collectionName,
      };
      await dispatch(
        mockupCollectionsApi.endpoints.createMockupCollection.initiate(data)
      ).unwrap();

      dispatch(
        mockupCollectionsApi.endpoints.getMockupCollections.initiate(null!, {
          forceRefetch: true,
        })
      );
      captureEvent('Collection created', {
        name: collectionName,
      });

      toast.success('Collection successfully created.', {
        toastId: 'create_collection_success',
        position: 'bottom-right',
        autoClose: 5000,
      });
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'create_collection_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
      captureEvent('Collection create failed', {
        error: error,
      });
    } finally {
      setOpen(false);
      setCreateCollectionIsLoading(false);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content aria-describedby={undefined} style={{ maxWidth: 450 }}>
        <Dialog.Title>
          <Flex direction={'row'} align={'center'} justify={'between'}>
            <Text>Create Collection</Text>
          </Flex>
        </Dialog.Title>
        <Flex direction={'column'}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            ref={formRef}
          >
            <label>
              <TextField.Slot>
                <Text weight={'medium'} size={'2'} mb={'1'}>
                  Collection Name
                </Text>
              </TextField.Slot>
              <TextField.Root
                placeholder="T-shirts"
                value={collectionName}
                {...register('collectionName', {
                  required: {
                    value: true,
                    message: 'Collection name field is required',
                  },
                })}
                onChange={(e) => setCollectionName(e.currentTarget.value)}
              />
            </label>
            {errors.collectionName && (
              <Text role="alert" size={'1'} mt={'1'} color="red">
                {errors.collectionName.message}
              </Text>
            )}
            <Flex gap="3" mt="4" justify="end">
              <Dialog.Close>
                <Button variant="soft" color="gray">
                  Cancel
                </Button>
              </Dialog.Close>
              <Button type="submit" loading={createCollectionIsLoading}>
                Create
              </Button>
            </Flex>
          </form>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
});
