import { Helmet } from 'react-helmet-async';
import './api-dashboard.scss';
import {
  Flex,
  ScrollArea,
  Text,
  Link,
  Button,
  Dialog,
  TextField,
  Table,
  Tooltip,
  AlertDialog,
  Card,
  Badge,
} from '@radix-ui/themes';
import { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import { apiKeyApi } from '@/services/apiKey';
import { ReactComponent as TrashBin } from '@/assets/icons/trash-bin.svg';
import { ReactComponent as PenIcon } from '@/assets/icons/pen-linear.svg';
import { toast } from 'react-toastify';
import { ApiKey } from '@/redux/slices/types';
import { ApiPaymentPopup } from './components/ApiPaymentPopup';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMediaQuery } from 'react-responsive';
import posthog from 'posthog-js';
import { paymentsApi } from '@/services/payments';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks';
import useCaptureEvent from '@/hooks/useCaptureEvent';

export const ApiDashboard = () => {
  const captureEvent = useCaptureEvent();
  const [open, setOpen] = useState<boolean>(false);
  const [editedApiKey, setEditedApiKey] = useState<ApiKey>(null!);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const { userRenders } = useAppSelector((state) => state.paymentsReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);

  const [searchParams, setSearchParams] = useSearchParams({});

  const apiKeysData = apiKeyApi.useGetApiKeysQuery();
  const userRendersData = paymentsApi.useGetUserRendersQuery(undefined, {
    pollingInterval: searchParams.get('checkout_session_id')
      ? userRenders !== undefined && userRenders !== null
        ? 0
        : 500
      : 0,
    refetchOnMountOrArgChange: true,
  });

  const [apiKeyName, setApiKeyName] = useState('');

  const [createApiKey, { isLoading: isCreating }] =
    apiKeyApi.useCreateApiKeyMutation();
  const [deleteApiKey, { isLoading: isDeleting }] =
    apiKeyApi.useDeleteApiKeyMutation();
  const [updateApiKey, { isLoading: isUpdating }] =
    apiKeyApi.useUpdateApiKeyMutation();

  const createApiKeyAction = async () => {
    try {
      await createApiKey({
        name: apiKeyName,
      }).unwrap();
      captureEvent('User Created API Key', {
        $set_once: { ['api_key_created']: true },
      });
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'api_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const deleteKeyAction = async (uuid: string) => {
    try {
      await deleteApiKey({ uuid });
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'api_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const updateApiKeyAction = async () => {
    try {
      await updateApiKey(editedApiKey);
    } catch (error) {
      console.log('error: ', error);
      toast.warning((error as unknown as any).data.message, {
        toastId: 'api_key_action_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const integrationsEnabled = useFeatureFlagEnabled('api-payments');

  const filterPlanName = (name: string | number | undefined) => {
    if (name === 'free') {
      return 'Free Plan';
    }
    return name;
  };

  return (
    <Flex className="view-wrapper-api-dashboard" direction={'column'} gap={'4'}>
      <Helmet>
        <title>Dynamic Mockups | API Dashboard</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Flex className="header" px={'5'} py={'3'} align={'center'}>
        <Text weight={'bold'} size={'5'}>
          API Dashboard
        </Text>
      </Flex>

      <ScrollArea
        className="scroll-area-wrapper"
        type="hover"
        scrollbars="vertical"
        style={{ height: 'calc(100vh - 77px)' }}
      >
        <Flex className="view-wrapper" direction={'column'} gap={'3'} px={'5'}>
          <Flex direction={'column'} align={'start'} gap={'4'}>
            <Flex
              direction={'row'}
              align={'center'}
              justify={'between'}
              width={'100%'}
              gap={'2'}
              className="items-card-wrapper"
            >
              <Card className="item-card" size={'5'}>
                <Flex direction={'column'}>
                  <Text className="title" size={'3'} weight={'bold'} mb={'5'}>
                    Set up the API
                  </Text>
                  <Text size={'2'}>
                    The Dynamic Mockups API allows you to create Mockups
                    programmatically and integrate the service within your own
                    app Web or Mobile app.
                  </Text>

                  <Flex
                    className="buttons-wrapper"
                    direction={'row'}
                    align={'center'}
                    gap={'3'}
                  >
                    <Dialog.Root>
                      <Dialog.Trigger>
                        <Button
                          color="gray"
                          variant="outline"
                          disabled={isCreating}
                          className="action"
                        >
                          <PlusIcon className="icon" />
                          <Text size="1" weight="medium">
                            Create new API Key
                          </Text>
                        </Button>
                      </Dialog.Trigger>

                      <Dialog.Content
                        aria-describedby={undefined}
                        style={{ maxWidth: 450 }}
                      >
                        <Dialog.Title>Name your App</Dialog.Title>
                        <Dialog.Description size="2" mb="4">
                          Name your App for which you want to create an API Key.
                        </Dialog.Description>

                        <Flex direction="column" gap="3">
                          <label>
                            <TextField.Root
                              onChange={(e) => setApiKeyName(e.target.value)}
                              value={apiKeyName}
                              placeholder="App Name"
                            />
                          </label>
                        </Flex>

                        <Flex gap="3" mt="4" justify="end">
                          <Dialog.Close>
                            <Button variant="soft" color="gray">
                              Cancel
                            </Button>
                          </Dialog.Close>
                          <Dialog.Close>
                            <Button
                              event-tracker-id="create-api-key"
                              variant="solid"
                              color="blue"
                              onClick={() => createApiKeyAction()}
                            >
                              Create
                            </Button>
                          </Dialog.Close>
                        </Flex>
                      </Dialog.Content>
                    </Dialog.Root>
                    <Link
                      href="https://docs.dynamicmockups.com"
                      target="_blank"
                    >
                      <Button
                        color="gray"
                        variant="ghost"
                        onClick={() => captureEvent('API Docs visited')}
                      >
                        Read API Docs
                      </Button>
                    </Link>
                  </Flex>
                </Flex>
              </Card>
              {integrationsEnabled && (
                <Card className="item-card" size={'5'}>
                  <Flex direction={'column'}>
                    <Text className="title" size={'3'} weight={'bold'} mb={'5'}>
                      Current Plan -{' '}
                      {filterPlanName(userRendersData.data?.data[0]?.plan) ||
                        'Free Plan'}
                    </Text>
                    <Text size={'2'} mb={'1'}>
                      Remaining Renders:{' '}
                      {userRendersData.data?.data[0]?.renders_remaining || '0'}/
                      {userRendersData.data?.data[0]?.total_renders || '0'}
                    </Text>
                    <Text size={'2'}>
                      Unused Renders roll over as long as you're subscribed.
                    </Text>
                    <Flex
                      className="buttons-wrapper"
                      direction={'row'}
                      align={'center'}
                      gap={'3'}
                    >
                      <ApiPaymentPopup>
                        <Button
                          className="action color"
                          variant="solid"
                          onClick={() => captureEvent('API Plans modal viewed')}
                        >
                          Upgrade
                        </Button>
                      </ApiPaymentPopup>
                    </Flex>{' '}
                  </Flex>
                </Card>
              )}
              {/* <Text size={'2'}>
                The Dynamic Mockups API allows you to integrate your application
                with our Mockup generator service.<br></br> See the
                <Link href="https://docs.dynamicmockups.com" target="_blank">
                  API Documentation
                </Link>
                for more details.
              </Text>
             */}
            </Flex>

            <Dialog.Root open={open} onOpenChange={setOpen}>
              <Dialog.Content aria-describedby={undefined}>
                <Dialog.Title>Edit App Name</Dialog.Title>
                <Dialog.Description>
                  Edit name of your App for which you use this API key.
                </Dialog.Description>

                <Flex direction="column" gap="3" my={'4'}>
                  <label>
                    <TextField.Root
                      onChange={(e) =>
                        setEditedApiKey({
                          ...editedApiKey,
                          name: e.target.value,
                        })
                      }
                      value={editedApiKey?.name}
                      placeholder="App Name"
                    />
                  </label>
                </Flex>

                <Flex gap="3" justify="end">
                  <Dialog.Close>
                    <Button variant="soft" color="gray">
                      Cancel
                    </Button>
                  </Dialog.Close>
                  <Dialog.Close>
                    <Button
                      variant="solid"
                      color="blue"
                      onClick={() => updateApiKeyAction()}
                    >
                      Update
                    </Button>
                  </Dialog.Close>
                </Flex>
              </Dialog.Content>
            </Dialog.Root>
            <Flex direction={'column'} style={{ width: '100%' }}>
              <Table.Root variant="surface" size="1" style={{ width: '100%' }}>
                <Table.Header>
                  <Table.Row>
                    <Table.ColumnHeaderCell>App Name</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>API Key</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                    {/* <Table.ColumnHeaderCell>Created</Table.ColumnHeaderCell> */}
                    <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
                  </Table.Row>
                </Table.Header>
                {((apiKeysData.data?.data as any)?.data || []).length > 0 && (
                  <Table.Body className="keys-table-wrapper">
                    {(apiKeysData.data?.data as any)?.data.map((key: any) => (
                      <Table.Row key={key.uuid}>
                        <Table.Cell>
                          <Flex>
                            <Text
                              style={{
                                display: 'inline-block',
                              }}
                            >
                              {key.name}
                            </Text>
                          </Flex>
                        </Table.Cell>

                        <Table.Cell>
                          <Flex gap={'1'}>
                            <Text
                              style={{
                                display: 'inline-block',
                              }}
                              className="key-text"
                            >
                              {key.api_key.toString().substring(0, 4) +
                                (isMobile ? '*' : '************************') +
                                key.api_key
                                  .toString()
                                  .substring(
                                    key.api_key.length - 4,
                                    key.api_key.length
                                  )}
                            </Text>
                            <Tooltip content="Copy API Key">
                              <Button
                                event-tracker-id="copy-api-key"
                                size={'1'}
                                variant="soft"
                                color="gray"
                                onClick={() => {
                                  navigator.clipboard.writeText(key.api_key);
                                  toast.success(
                                    'API Key copied to clipboard.',
                                    {
                                      toastId: 'api_key_action_error',
                                      position: 'bottom-right',
                                      autoClose: 1500,
                                    }
                                  );
                                }}
                              >
                                <CopyIcon
                                  className="icon"
                                  width={'14px'}
                                  height={'14px'}
                                />
                              </Button>
                            </Tooltip>
                          </Flex>
                        </Table.Cell>
                        <Table.Cell>
                          <Flex>
                            <Text
                              style={{
                                display: 'inline-block',
                              }}
                            >
                              <Badge color="green">Active</Badge>
                            </Text>
                          </Flex>
                        </Table.Cell>
                        {/* <Table.Cell>
                          <Flex>
                            <Text
                              style={{
                                display: 'inline-block',
                              }}
                            >
                              19/02/24
                            </Text>
                          </Flex>
                        </Table.Cell> */}
                        <Table.Cell className="actions-buttons">
                          <Tooltip content="Edit key name">
                            <Button
                              event-tracker-id="edit-api-key"
                              size={'1'}
                              variant="soft"
                              color="gray"
                              mx={'2'}
                              onClick={() => {
                                setOpen(true);
                                setEditedApiKey(key);
                              }}
                            >
                              <PenIcon
                                className="icon"
                                width={'14px'}
                                height={'14px'}
                              />
                            </Button>
                          </Tooltip>
                          <AlertDialog.Root>
                            <Tooltip content="Delete key">
                              <AlertDialog.Trigger>
                                <Button size={'1'} variant="soft" color="gray">
                                  <TrashBin
                                    className="icon"
                                    width={'14px'}
                                    height={'14px'}
                                  />
                                </Button>
                              </AlertDialog.Trigger>
                            </Tooltip>
                            <AlertDialog.Content style={{ maxWidth: 600 }}>
                              <AlertDialog.Title>
                                Delete API key
                              </AlertDialog.Title>
                              <AlertDialog.Description size="2">
                                Are you sure you want to delete API key used for
                                '{key.name}'?
                              </AlertDialog.Description>

                              <Flex gap="3" mt="4" justify="end">
                                <AlertDialog.Cancel>
                                  <Button variant="soft" color="gray">
                                    Cancel
                                  </Button>
                                </AlertDialog.Cancel>
                                <AlertDialog.Cancel>
                                  <Button
                                    event-tracker-id="delete-api-key"
                                    variant="solid"
                                    color="red"
                                    onClick={() => deleteKeyAction(key.uuid)}
                                  >
                                    Delete
                                  </Button>
                                </AlertDialog.Cancel>
                              </Flex>
                            </AlertDialog.Content>
                          </AlertDialog.Root>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                )}
              </Table.Root>
              {((apiKeysData.data?.data as any)?.data || []).length === 0 && (
                <Flex
                  align={'center'}
                  justify={'center'}
                  p={'3'}
                  style={{ width: '100%' }}
                >
                  <Text size={'1'}>There are no active API Keys</Text>
                </Flex>
              )}
            </Flex>

            <Flex direction={'column'} gap={'4'} width="100%"></Flex>
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

