import { Button, Flex, AlertDialog } from '@radix-ui/themes';
import { useAppDispatch } from '@/hooks';
import { psdManagementApi } from '@/services/psdManagement';
import { useState, forwardRef, useImperativeHandle } from 'react';
import { Project } from '@/services/types';
import { workspaceApi } from '@/services/workspace';
import { mockupCollectionsApi } from '@/services/mockupCollections';

interface IDeleteMyTemplate {
  project: Project;
}

export const DeleteMyTemplate = forwardRef(
  ({ project }: IDeleteMyTemplate, ref) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);

    const [deleteProject] = workspaceApi.useDeleteMyProjectMutation();

    useImperativeHandle(ref, () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    const deleteMyTemplateAction = async (id: number) => {
      deleteProject({ id });
      dispatch(
        mockupCollectionsApi.endpoints.getMockupCollections.initiate(null!, {
          forceRefetch: true,
        })
      );
    };

    return (
      <AlertDialog.Root open={open} onOpenChange={setOpen}>
        <AlertDialog.Content style={{ maxWidth: 450 }}>
          <AlertDialog.Title>Delete Template</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Are you sure you want to delete this Template?
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Cancel>
              <Button
                variant="solid"
                color="red"
                onClick={() =>
                  deleteMyTemplateAction(project.project_id as number)
                }
              >
                Delete
              </Button>
            </AlertDialog.Cancel>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }
);
