import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { UserRenders } from '@/redux/slices/types';
import { DataModelArray } from './types';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUserRenders: builder.query<DataModelArray<UserRenders>, void>({
      query: () => `user_renders`,
    }),
    getCheckoutSession: builder.query<any, { checkoutSessionId: string }>({
      query: ({ checkoutSessionId }) =>
        `stripe/checkout/session/${checkoutSessionId}`,
    }),
  }),
});

export const {} = paymentsApi;
